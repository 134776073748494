import { API_VEHICLE_TYPE_LIST, MAX_PER_PAGE } from 'constants/apiConstants'
import { COUNTRY_CODE_INDONESIA } from 'constants/dynamicFieldConstants'
import i18n from 'i18n/i18n'
import Model from '../Model'

/**
 * @class VehicleType
 */
export default class VehicleType extends Model {
  getList(_data = {}) {
    let data = Object.assign({}, _data)
    return this._api.get(API_VEHICLE_TYPE_LIST, data)
  }

  getById = (vehicleTypeId, params) => {
    const url = `vehicle_types/${vehicleTypeId}`
    return this._api.get(url, params).then(response => response.object)
  }

  getAllTypes = async (data) => {
    const typeList = []

    // Allow maximum 50 api calls continuously only
    const maxPageNumbers = Array.from(new Array(50)).map((x, index) => index + 1)

    // FYI: for...of can support await syntax, 
    // so the next loop only execute when the previous one is resolved
    for (const page of maxPageNumbers) {
      const params = {
        per_page: MAX_PER_PAGE,
        ...data,
        page,
      }
      const { data: typeListByPage } = await this.getList(params)
      typeList.push(...typeListByPage)

      // In case the received list length is less than the MAX_PER_PAGE
      // it means no more data on the next request
      // so, we can stop the loop ... yayyyyy :P
      if (typeListByPage.length < MAX_PER_PAGE) break
    }
    sessionStorage.setItem('Data_ListVehicleType', JSON.stringify(typeList))
    return typeList
  }

  getAllTypeOptions = async (data) => {
    let typeList = await this.getAllTypes(data)

    // In case of no mapping found with input data
    // We may need to fetch all make option then 
    // TODO: Cache resut of this options
    if (typeList.length === 0) {
      const params = {
        area_id: data.area_id,
      }
      typeList = await this.getAllTypes(params)
    }

    // Add OTHER option exception Indo - Vehicle Type
    if (data.countryCode !== COUNTRY_CODE_INDONESIA) {
      typeList.push({
        id: 0,
        name: i18n.t('common.form_other'),
      })
    }
    return typeList.map(x => ({
      value: x.id,
      label: x.note
        ? `${x.name} (${x.note})`
        : x.name,
      isStaff: x.vehicle_type_is_deliveree_staff
    }))
  }
}
