/*
 * @flow
 */

import {
  CALL_LOADING,
  UPDATE_COMPETITOR_APP
} from '../constants/appConstants'

const initialState = {
  loading: false,
  competitorApp: []
}

export function common(state = initialState, action) {
  if (action.type === CALL_LOADING) {
    return {
      ...state,
      loading: true,
    }
  }

  if (action.type === UPDATE_COMPETITOR_APP) {
    return {
      ...state,
      competitorApp: action.data || [],
    }
  }

  return state
}
