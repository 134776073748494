/** @var {resources} */
const resources = {
  en: {
    translation: {
      common: require('./locales/common/en.json'),
      complete_application: require('./locales/completeApplication/en.json'),
      finish_application: require('./locales/finishApplication/en.json'),
      sign_up: require('./locales/signUp/en.json'),
      select_area: require('./locales/selectArea/en.json'),
      select_country: require('./locales/selectCountry/en.json'),
      submit_successful: require('./locales/submitSuccessful/en.json'),
      successful_page: require('./locales/successfulPage/en.json'),
      verify_number: require('./locales/veifyNumber/en.json'),
      confirmation_page: require('./locales/confirmationPage/en.json'),
      sign_in: require('./locales/signIn/en.json'),
      e_training: require('./locales/eTraining/en.json'),
      agreement: require('./locales/agreement/en.json'),
      select_address: require('./locales/selectAddress/en.json'),
      download_app: require('./locales/downloadApp/en.json'),
    }
  },
  id: {
    translation: {
      common: require('./locales/common/id.json'),
      complete_application: require('./locales/completeApplication/id.json'),
      finish_application: require('./locales/finishApplication/id.json'),
      sign_up: require('./locales/signUp/id.json'),
      select_area: require('./locales/selectArea/id.json'),
      select_country: require('./locales/selectCountry/id.json'),
      submit_successful: require('./locales/submitSuccessful/id.json'),
      successful_page: require('./locales/successfulPage/id.json'),
      verify_number: require('./locales/veifyNumber/id.json'),
      confirmation_page: require('./locales/confirmationPage/id.json'),
      sign_in: require('./locales/signIn/id.json'),
      e_training: require('./locales/eTraining/id.json'),
      agreement: require('./locales/agreement/id.json'),
      select_address: require('./locales/selectAddress/id.json'),
      download_app: require('./locales/downloadApp/id.json'),
    }
  },
  th: {
    translation: {
      common: require('./locales/common/th.json'),
      complete_application: require('./locales/completeApplication/th.json'),
      finish_application: require('./locales/finishApplication/th.json'),
      sign_up: require('./locales/signUp/th.json'),
      select_area: require('./locales/selectArea/th.json'),
      select_country: require('./locales/selectCountry/th.json'),
      submit_successful: require('./locales/submitSuccessful/th.json'),
      successful_page: require('./locales/successfulPage/th.json'),
      verify_number: require('./locales/veifyNumber/th.json'),
      confirmation_page: require('./locales/confirmationPage/th.json'),
      sign_in: require('./locales/signIn/th.json'),
      e_training: require('./locales/eTraining/th.json'),
      agreement: require('./locales/agreement/th.json'),
      select_address: require('./locales/selectAddress/th.json'),
      download_app: require('./locales/downloadApp/th.json'),
    }
  },
  tl: {
    translation: {
      common: require('./locales/common/tl.json'),
      complete_application: require('./locales/completeApplication/tl.json'),
      finish_application: require('./locales/finishApplication/tl.json'),
      sign_up: require('./locales/signUp/tl.json'),
      select_area: require('./locales/selectArea/tl.json'),
      select_country: require('./locales/selectCountry/tl.json'),
      submit_successful: require('./locales/submitSuccessful/tl.json'),
      successful_page: require('./locales/successfulPage/tl.json'),
      verify_number: require('./locales/veifyNumber/tl.json'),
      confirmation_page: require('./locales/confirmationPage/tl.json'),
      sign_in: require('./locales/signIn/tl.json'),
      e_training: require('./locales/eTraining/tl.json'),
      agreement: require('./locales/agreement/tl.json'),
      select_address: require('./locales/selectAddress/tl.json'),
      download_app: require('./locales/downloadApp/tl.json'),
    }
  },
}

export default resources
