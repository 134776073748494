/**
 * Project's reducers
 */
import { combineReducers } from 'redux'
//
import * as common from './common'
import driverOnboarding from './driverOnboarding'
import topLeftMenu from './topLeftMenu'
import showGoogleMap from './showGoogleMap'
import area from './area'
import eTraining from './eTraining'

// export default
const reducers = combineReducers({
  ...common,
  driverOnboarding,
  topLeftMenu,
  area,
  eTraining,
  showGoogleMap
})

export default reducers
