import {
  GET_E_TRAINING_EXAMS_SUCCESS,
  GET_E_TRAINING_EXAMS_FAILED,
  UPDATE_E_TRAINING_EXAMS_LIST_STATUS,
  RESET_CURRENT_FAILED_EXAM
} from 'actions/actionType'
import { EXAM_STATUS } from 'constants/appConstants'

const initialState = {
  examsList: [],
  examsLog: [],
  currentFailedExam: {},
  error: null
}

const eTraining = (state = initialState, action) => {
  switch (action.type) {
    case GET_E_TRAINING_EXAMS_SUCCESS: {
      return processGetExamsList(action, state)
    }

    case GET_E_TRAINING_EXAMS_FAILED: {
      return {
        ...state,
        error: action.payload,
      }
    }

    case UPDATE_E_TRAINING_EXAMS_LIST_STATUS: {
      return processUpdateExamStatus(action, state)
    }

    case RESET_CURRENT_FAILED_EXAM: {
      return {
        ...state,
        currentFailedExam: {}
      }
    }

    default:
      return state
  }
}

const processGetExamsList = (action, state) => {
  /* Check status of exam item
  *  If no, check previous one to detect the status of exam item
  */
  const { data } = action.payload

  let examsList = (data.exams && data.exams.data) ? [...data.exams.data] : []
  const seqId = data.seq_id

  const examsLog = (data.exam_logs && data.exam_logs.data) ? [...data.exam_logs.data] : []

  const newExamsList = mapStatusIntoExam(examsList, examsLog)

  newExamsList.forEach((exam, index) => {
    if (index > 0) {
      exam.prevExamName = newExamsList[index - 1].name
    }
  })

  return {
    ...state,
    examsList: newExamsList,
    examsLog,
    seqId
  }
}

const mapStatusIntoExam = (examsList, examsLog) => {
  const newExamsList = JSON.parse(JSON.stringify(examsList))

  newExamsList.forEach((item, index) => {
    const examInLog = examsLog.find(x => x.exam_id === item.id)

    if (!examInLog && index === 0) {
      item.status = EXAM_STATUS.UNCHECKED
      return
    }

    if (!examInLog && index > 0) {
      const prevExamInLog = examsLog.find(x => x.exam_id === newExamsList[index - 1].id)
      item.status = (prevExamInLog && prevExamInLog.status === EXAM_STATUS.CLEARED) ? EXAM_STATUS.UNCHECKED : EXAM_STATUS.LOCKED
      return
    }

    if (examInLog) {
      item.status = examInLog.status
    }

    if (examInLog.status === EXAM_STATUS.BLOCKED) {
      item.retake_unblocked_at = examInLog.retake_unblocked_at
      return
    }

    /* Exam has status cleared */
    item.updated_at = examInLog.updated_at
  })

  return newExamsList
}

const processUpdateExamStatus = (action, state) => {
  const { data } = action
  const stateExamsLog = Array.from(state.examsLog)
  const dataKeys = Object.keys(data)
  let currentFailedExam = JSON.parse(JSON.stringify(state.currentFailedExam))
  /* Handle case on firebase, exam has status failed turn into null (mean cleared) */
  // stateExamsLog.forEach(exam => {
  //   if (exam.status === EXAM_STATUS.FAILED && !dataKeys.includes(exam.exam_id.toString())) {
  //     exam.status = EXAM_STATUS.CLEARED
  //   }
  // })

  /* Handle the normal case */
  dataKeys.forEach(key => {
    // const { status, fail_number } = data[key]
    const status = data[key]?.status.name
    const fail_number = data[key]?.failNumber
    const hasExamLog = stateExamsLog.find(x => x.exam_id.toString() === key.toString())

    if (!hasExamLog) {
      stateExamsLog.push({
        ...data[key],
        exam_id: key,
        failNumber: fail_number ? fail_number : 0
      })

      if (status === EXAM_STATUS.FAILED) {
        currentFailedExam = findCurrentFailedExam(state.examsList, key)
      }

      return
    }

    hasExamLog.status = status
    if (status === EXAM_STATUS.FAILED) {
      if (hasExamLog.failNumber < fail_number) {
        currentFailedExam = findCurrentFailedExam(state.examsList, key)
      }
      hasExamLog.failNumber = fail_number
    }
  })

  const newExamsList = mapStatusIntoExam(state.examsList, stateExamsLog)

  return {
    ...state,
    examsLog: [...stateExamsLog],
    examsList: newExamsList,
    currentFailedExam
  }
}

const findCurrentFailedExam = (examList, id) => {
  return examList.find(x => x.id.toString() === id.toString())
}

export default eTraining