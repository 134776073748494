import {
  API_DRIVER_ONBOARDING_V4,
  API_VERIFY_TRAINING_CODE,
  API_UPLOAD_TEMPORARY_FILES,
  API_LOAD_ATTACHMENTS_URL
} from 'constants/apiConstants'
import ModelV4 from '../ModelV4'
import { setDriverRegistrationID } from 'utils/common'

/**
 * @class DriverOnboardingV4
 */
export default class DriverOnboardingV4 extends ModelV4 {
  async verifyTrainingCode(_data = {}) {
    let data = Object.assign({}, _data)
    const verifyData = await this._api.post(API_VERIFY_TRAINING_CODE, data)
    if (verifyData?.seq_id) setDriverRegistrationID(verifyData.seq_id)
    return verifyData
  }

  verifyActivationCode(data = {}) {
    const id = data.id || ''
    const apiURL = `${API_DRIVER_ONBOARDING_V4}/${id}/verify_sms_token`
    return this._api.post(apiURL, data)
  }

  saveDataOnboardingForm(data) {
    const id = data.id || ''
    const apiURL = `${API_DRIVER_ONBOARDING_V4}/${id}/draft`
    return this._api.put(apiURL, data)
  }

  resendActivationCode(data) {
    const id = data.id || ''
    const apiURL = `${API_DRIVER_ONBOARDING_V4}/${id}/resend_sms_token`
    return this._api.patch(apiURL, data)
  }

  sendTrainingLink(id) {
    const apiURL = `${API_DRIVER_ONBOARDING_V4}/${id}/training/send-link`
    return this._api.patch(apiURL)
  }

  suspendCompetitorApp({id, payload}) {
    const apiURL = `${API_DRIVER_ONBOARDING_V4}/${id}/policy/competitor-app`
    return this._api.patch(apiURL, payload).then(response => response)
  }

  async signUp(_data = {}) {
    const approvalCode = sessionStorage.getItem('retraining_approval_code')
    const data = Object.assign({}, _data, {approval_code: approvalCode})
    
    const queryParameters = new URLSearchParams(window.location.search)
    const driver = await this._api.post(API_DRIVER_ONBOARDING_V4, data, {
      headers: {
        "x-url-parameters": queryParameters.toString()
      }
    })
    if (driver?.object?.seq_id) setDriverRegistrationID(driver.object.seq_id)
    return driver
  }
  retraining(id, payload){
    const apiURL = `${API_DRIVER_ONBOARDING_V4}/${id}/section/A`
    return this._api.patch(apiURL, payload)
  }
  getInfoDriver(id) {
    const apiURL = `${API_DRIVER_ONBOARDING_V4}/${id}`
    return this._api.get(apiURL).then(response => response.object)
  }

  updateChecklist(id, payload) {
    const apiURL = `${API_DRIVER_ONBOARDING_V4}/${id}/checklist`
    return this._api.patch(apiURL, payload).then(response => response.object)
  }

  fillInSectionBC(id, payload) {
    const apiURL = `${API_DRIVER_ONBOARDING_V4}/${id}/section/BC`
    return this._api.patch(apiURL, payload).then(response => response.object)
  }

  fillInSectionABC(id, payload) {
    const apiURL = `${API_DRIVER_ONBOARDING_V4}/${id}/onboarding-form`
    return this._api.patch(apiURL, payload).then(response => response.object)
  }

  uploadTemporaryFiles(payload) {
    const headers = {
      'content-type': 'multipart/form-data',
    }
    const apiURL = `${API_UPLOAD_TEMPORARY_FILES}`
    const formData = new FormData()
    const files = payload
    for (let i = 0; i < files.length; i++) {
        formData.append(`files`, files[i],files[i].name)
    }
    return this._api.post(apiURL, formData, {headers: headers})
  }

  loadAttachmentsUrl(payload){
    let apiUrl = `${API_LOAD_ATTACHMENTS_URL}?streamId=`+ payload.join("&streamId=")  
    return this._api.get(apiUrl)
  }

  getOnboardingSetting(countryCode) {
    const apiURL = `/v4/driver/onboarding/settings/${countryCode}`
    return this._api.get(apiURL)
  }
}
