import i18n from 'i18n/i18n'
import {
  API_AREA,
  API_AREA_DRIVER_ONBAORDING_ATTRIBUTES,
  API_AREA_PROVINCES_LIST,
  API_AREA_PROVINCE_DISTRICTS_LIST,
  API_SELECT_AREA_LIST,
  MAX_PER_PAGE,
} from 'constants/apiConstants'
import Model from '../Model'

/**
 * @class AreaAPI
 */
export default class Area extends Model {

  getList(_data = {}) {
    let data = Object.assign({}, _data)
    return this._api.get(API_AREA, data)
  }
  getAreaById(id, _data = {}) {
    let data = Object.assign({}, _data)
    return this._api.get(`${API_AREA}/${id}`, data)
  }

  getDriverOnboardingAttributes(areaID, _data = {}) {
    let data = Object.assign({}, _data)
    const apiURL = [API_SELECT_AREA_LIST, areaID, API_AREA_DRIVER_ONBAORDING_ATTRIBUTES].join('/')
    return this._api.get(apiURL, data)
  }

  getProvinces(areaID, _data = {}) {
    let data = Object.assign({ area_id: areaID }, _data)
    const apiURL = [API_SELECT_AREA_LIST, API_AREA_PROVINCES_LIST].join('/')
    return this._api.get(apiURL, data)
  }

  getDistricts(proviceID, _data = {}) {
    let data = Object.assign({ province_id: proviceID }, _data)
    const apiURL = [API_SELECT_AREA_LIST, API_AREA_PROVINCE_DISTRICTS_LIST].join('/')
    return this._api.get(apiURL, data)
  }

  getAllProvinces = async (areaId) => {
    const provinceList = []

    // Allow maximum 50 api calls continuously only
    const maxPageNumbers = Array.from(new Array(50)).map((x, index) => index + 1)

    // FYI: for...of can support await syntax, 
    // so the next loop only execute when the previous one is resolved
    for (const page of maxPageNumbers) {
      const params = {
        page,
        per_page: MAX_PER_PAGE,
      }
      const provinceListByPage = await this.getProvinces(areaId, params)
      provinceList.push(...provinceListByPage)

      // In case the received list length is less than the MAX_PER_PAGE
      // it means no more data on the next request
      // so, we can stop the loop ... yayyyyy :P
      if (provinceListByPage.length < MAX_PER_PAGE) break
    }

    return provinceList
  }

  getAllDistricts = async (provinceId) => {
    const districtList = []

    // Allow maximum 50 api calls continuously only
    const maxPageNumbers = Array.from(new Array(50)).map((x, index) => index + 1)

    // FYI: for...of can support await syntax, 
    // so the next loop only execute when the previous one is resolved
    for (const page of maxPageNumbers) {
      const params = {
        page,
        per_page: MAX_PER_PAGE,
      }
      const districtListByPage = await this.getDistricts(provinceId, params)
      districtList.push(...districtListByPage)

      // In case the received list length is less than the MAX_PER_PAGE
      // it means no more data on the next request
      // so, we can stop the loop ... yayyyyy :P
      if (districtListByPage.length < MAX_PER_PAGE) break
    }

    // For district, we need to support OTHER option
    const otherOption = {
      id: 0,
      province_id: provinceId,
      name: i18n.t('finish_application.option_other'),
    }
    districtList.push(otherOption)

    return districtList
  }

  getByLocation = async (location) => {
    if (!location.lat || !location.lng) return null

    const apiURL = [API_AREA, 'current'].join('/')
    const params = {
      latitude: location.lat,
      longitude: location.lng,
    }
    const response = await this._api.get(apiURL, params)
    return response.object
  }
}

