import { UPDATE_DYNAMIC_FIELD } from "actions/actionType"
import AreaApi from 'api/Area'
import { OTHER_OPTION, EMAIL_REGEX } from "constants/appConstants"
import {
  ALTERNATE_NUMBER,
  BANK_MOBILE_WALLET_GROUP,
  YOUR_VEHICLE_GROUP,
  BANK_NAME,
  COUNTRIES_USE_PROVINCE_AS_TEXTBOX,
  COUNTRY_CODE_INDONESIA,
  DATE,
  DISTRICT,
  EMERGENCY_CONTACT_NAME,
  EMERGENCY_CONTACT_NUMBER,
  EMERGENCY_CONTACT_RELATIONSHIP,
  GCASH_BIRTHDATE,
  GCASH_FIRST_NAME,
  GCASH_LAST_NAME,
  GCASH_MIDDLE_NAME,
  GCASH_MOBILE_NUMBER,
  ID_NUMBER,
  LICENSE_EXPIRATION_DATE,
  LICENSE_NUMBER,
  LIFE_TIME_LICENSE,
  LOADING_CAPAICTY,
  MAX_CARGO,
  MULTIPLE_CHOICE,
  NUMBER_PASSENGER_SEAT,
  OTHER_BANK_NAME,
  OTHER_INFORMATION_GROUP,
  PLATE_NUMBER,
  PROVINCE,
  STRING,
  TAX_ID,
  TYPE_PHONE,
  VEHICLE_HEIGHT,
  VEHICLE_LENGHT,
  VEHICLE_VOLUME,
  VEHICLE_WIDTH,
  LIMIT_YEARS_BIRTHDAY,
  VEHICLE_TAX_EXPIRATION_DATE,
  VEHICLE_REGISTRATION_EXPIRATION_DATE,
  VEHICLE_KEUR_EXPIRATION_DATE,
  REGISTERED_ADDRESS,
  CURRENT_ADDRESS,
  TEMPERATURE_CAPACITY,
  COOLANT_TYPE,
  OTHER_COOLANT_TYPE,
  TOTAL_HEIGHT,
  TOTAL_LENGTH,
  GCASH_EMAIL_ADDRESS,
  VIBER_PHONE_NUMBER,
  BANK_ACCOUNT_NUMBER} from "constants/dynamicFieldConstants"
import i18n from 'i18n/i18n'
import * as _ from 'lodash'
import moment from 'moment'
import store from "store"
import * as Yup from 'yup'
import { parseValueField} from 'utils/common'
import { COUNTRY_CODE_THAILAND } from 'constants/dynamicFieldConstants'

const areaApi = new AreaApi()

// In case you want to override something from api 
// Just listing out here with format:
//  - key: attribute_key
//  - value: all the properties that you want to override
export const getDynamicFieldOverride = (driverOnboarding) => ({
  // only allow integers
  [VEHICLE_LENGHT]: {
    attribute_type: 'number',
    min: 0,
    parse: value => parseInt(value),
    onChange: ((newValue, field, form) => {
      const { carge_width, carge_height } = form.values

      const formFieldKeys = Object.keys(form.values)
      const length = formFieldKeys.includes('carge_length') ? newValue : 1
      const width = formFieldKeys.includes('carge_width') ? carge_width : 1
      const height = formFieldKeys.includes('carge_height') ? carge_height : 1

      // For Indonesia, they use cm for width, height and length --> volume is in cm3
      // Then we need to convert from cm3 --> m3, that's why we need to divide by 10^6 = 1e6
      const volume = driverOnboarding.country_code === COUNTRY_CODE_INDONESIA
        ? length * width * height / 1e6
        : length * width * height

      // The calculation: 1/1 helps to remove zeroes after decimal point
      // Eg: 123.00 --> 123
      form.setFieldValue(VEHICLE_VOLUME, volume.toFixed(2) * 1 / 1)
    })
  },

  // only allow integers
  [VEHICLE_HEIGHT]: {
    attribute_type: 'number',
    min: 0,
    parse: value => parseInt(value),
    onChange: ((newValue, field, form) => {
      const { carge_length, carge_width } = form.values

      const formFieldKeys = Object.keys(form.values)
      const length = formFieldKeys.includes('carge_length') ? carge_length : 1
      const width = formFieldKeys.includes('carge_width') ? carge_width : 1
      const height = formFieldKeys.includes('carge_height') ? newValue : 1

      // For Indonesia, they use cm for width, height and length --> volume is in cm3
      // Then we need to convert from cm3 --> m3, that's why we need to divide by 10^6 = 1e6
      const volume = driverOnboarding.country_code === COUNTRY_CODE_INDONESIA
        ? length * width * height / 1e6
        : length * width * height

      // The calculation: 1/1 helps to remove zeroes after decimal point
      // Eg: 123.00 --> 123
      form.setFieldValue(VEHICLE_VOLUME, volume.toFixed(2) * 1 / 1)
    })
  },

  // only allow integers
  [VEHICLE_WIDTH]: {
    attribute_type: 'number',
    min: 0,
    parse: value => parseInt(value),
    onChange: ((newValue, field, form) => {
      const { carge_length, carge_height } = form.values

      const formFieldKeys = Object.keys(form.values)
      const length = formFieldKeys.includes('carge_length') ? carge_length : 1
      const width = formFieldKeys.includes('carge_width') ? newValue : 1
      const height = formFieldKeys.includes('carge_height') ? carge_height : 1

      // For Indonesia, they use cm for width, height and length --> volume is in cm3
      // Then we need to convert from cm3 --> m3, that's why we need to divide by 10^6 = 1e6
      const volume = driverOnboarding.country_code === COUNTRY_CODE_INDONESIA
        ? length * width * height / 1e6
        : length * width * height

      // The calculation: 1/1 helps to remove zeroes after decimal point
      // Eg: 123.00 --> 123
      form.setFieldValue(VEHICLE_VOLUME, volume.toFixed(2) * 1 / 1)
    })
  },

  [LOADING_CAPAICTY]: {
    attribute_type: 'number',
    parse: value => parseValueField.NumberAndCharacter(value, LOADING_CAPAICTY)
  },

  [NUMBER_PASSENGER_SEAT]: {
    pattern: '[0-9]*',
    attribute_type: 'number',
    min: 0,
    parse: value => parseInt(value)
  },

  [PLATE_NUMBER]: {
    parse: value => parseValueField.NumberAndCharacter(value).toUpperCase()
  },

  [TAX_ID]: {
    maxLength: driverOnboarding.country_code === COUNTRY_CODE_INDONESIA ? 15 : null,
    parse: parseValueField.OnlyNumber, // only allow numbers
    pattern: '[0-9]*',
    inputType: 'tel', // pattern + input type = tel --> show numbered keyboard on mobile both on android + ios
  },

  [LICENSE_NUMBER]: {
    attribute_type: 'string',
    pattern: driverOnboarding.country_code === COUNTRY_CODE_INDONESIA ? '[0-9]*' : undefined,
    inputType: driverOnboarding.country_code === COUNTRY_CODE_INDONESIA ? 'tel' : 'text',
    parse: value => {
      return driverOnboarding.country_code === COUNTRY_CODE_INDONESIA
        ? parseValueField.OnlyNumber(value) // only allow numbers
        : driverOnboarding.country_code === COUNTRY_CODE_THAILAND
        ? parseValueField.AcceptAllCharacter(value)
        : parseValueField.NumberAndCharacter(value) // only allow chars + numbers
    }
  },

  [ID_NUMBER]: {
    pattern: '[0-9]*',
    inputType: 'tel', // pattern + input type = tel --> show numbered keyboard on mobile both on android + ios
    parse: parseValueField.OnlyNumber, // only allow numbers
  },

  [ALTERNATE_NUMBER]: {
    attribute_type: TYPE_PHONE,
    pattern: '[0-9]*',
    inputType: 'tel', // pattern + input type = tel --> show numbered keyboard on mobile both on android + ios
    parse: parseValueField.OnlyNumber, // only allow numbers
  },

  [EMERGENCY_CONTACT_NUMBER]: {
    attribute_type: TYPE_PHONE,
    pattern: '[0-9]*',
    inputType: 'tel', // pattern + input type = tel --> show numbered keyboard on mobile both on android + ios
    parse: parseValueField.OnlyNumber, // only allow numbers
  },

  [EMERGENCY_CONTACT_NAME]: {
    parse: parseValueField.OnlyCharacter, // not allow numbers
  },

  [EMERGENCY_CONTACT_RELATIONSHIP]: {
    parse: parseValueField.OnlyCharacter, // not allow numbers
  },

  [GCASH_BIRTHDATE]: {
    attribute_type: DATE,
    minDate: moment().add(-LIMIT_YEARS_BIRTHDAY, 'years'),
    maxDate: moment(),
  },

  [GCASH_MOBILE_NUMBER]: {
    attribute_type: TYPE_PHONE,
  },

  [VIBER_PHONE_NUMBER]: {
    attribute_type: TYPE_PHONE,
  },

  [GCASH_FIRST_NAME]: {
    parse: parseValueField.OnlyCharacter // not allow numbers
  },

  [GCASH_MIDDLE_NAME]: {
    parse: parseValueField.OnlyCharacter // not allow numbers
  },

  [GCASH_LAST_NAME]: {
    parse: parseValueField.OnlyCharacter // not allow numbers
  },

  [GCASH_EMAIL_ADDRESS]: {
    parse: parseValueField.AcceptAllCharacter
  },

  // Rules:
  // For ID + PH, use textbox
  // Others: use drop down
  [PROVINCE]: {
    attribute_type: COUNTRIES_USE_PROVINCE_AS_TEXTBOX.includes(driverOnboarding.country_code) ? STRING : MULTIPLE_CHOICE,
    onChange: async ({ selectedOption, field, form }) => {
      // Reset current selected option of district dropdown
      // Use either empty string or null to reset
      // As we need to trigger an update to DISTRICT field in order to make it re-render 
      form.setFieldTouched(DISTRICT, false)
      form.setFieldValue(DISTRICT, null)

      // In case province change, we need to reload district dropdown
      // Cache current result to session storage
      try {
        const provinceId = selectedOption ? selectedOption.value : null
        const districtList = provinceId
          ? await areaApi.getAllDistricts(provinceId)
          : []

        const districtOptions = districtList.map(x => ({
          value: x.id,
          label: x.name,
        }))

        // REDUX: Update optionList of DISTRICT field
        const action = {
          type: UPDATE_DYNAMIC_FIELD,
          payload: {
            groupName: OTHER_INFORMATION_GROUP,
            attribute_key: DISTRICT,
            changes: {
              disabled: false,
              optionList: districtOptions
            }
          }
        }
        store.dispatch(action)
      } catch (error) {
        console.log('Failed to fetch district list: ', error)
      }
    }
  },

  [DISTRICT]: {
    placeholder: i18n.t('common.select_province_first')
  },

  [BANK_NAME]: {
    onChange: async ({ selectedOption, field, form }) => {
      // If bank name is other
      //  - show other_bank_name textbox
      // Otherwise
      //  - hide other_bank_name textbox
      //  - reset its value
      const isOtherOptionSelected = _.get(selectedOption, 'value', null) === OTHER_OPTION

      if (!isOtherOptionSelected) {
        form.setFieldValue(OTHER_BANK_NAME, '')
      }

      // REDUX: Update visible attribute of OTHER_BANK_NAME field
      const action = {
        type: UPDATE_DYNAMIC_FIELD,
        payload: {
          groupName: BANK_MOBILE_WALLET_GROUP,
          attribute_key: OTHER_BANK_NAME,
          changes: {
            visible: isOtherOptionSelected
          }
        }
      }
      store.dispatch(action)
    }
  },
  [BANK_ACCOUNT_NUMBER]: {
    inputType: 'tel',
    pattern: '[0-9]*',
    parse: value => parseValueField.OnlyNumber(value).substr(0, 20)
  },
  [COOLANT_TYPE]: {
    onChange: async ({ selectedOption, field, form }) => {
      // If coolant type  is other
      //  - show OTHER_COOLANT_TYPE textbox
      // Otherwise
      //  - hide OTHER_COOLANT_TYPE textbox
      //  - reset its value
      const isOtherOptionSelected = _.get(selectedOption, 'value', null) === OTHER_OPTION

      if (!isOtherOptionSelected) {
        form.setFieldValue(OTHER_COOLANT_TYPE, '')
      }

      // REDUX: Update visible attribute of OTHER_COOLANT_TYPE field
      const action = {
        type: UPDATE_DYNAMIC_FIELD,
        payload: {
          groupName: YOUR_VEHICLE_GROUP,
          attribute_key: OTHER_COOLANT_TYPE,
          changes: {
            visible: isOtherOptionSelected
          }
        }
      }
      store.dispatch(action)
    }
  },
  [REGISTERED_ADDRESS]: {
    inputType: 'textarea',
    parse: parseValueField.AcceptAllCharacter
  },
  [CURRENT_ADDRESS]: {
    inputType: 'textarea',
    parse: parseValueField.AcceptAllCharacter
  },
  [TEMPERATURE_CAPACITY]: {},
  [TOTAL_HEIGHT]: {
    pattern: '[0-9]*',
    attribute_type: 'number',
    min: 0,
    parse: value => parseInt(value)
  },
  [TOTAL_LENGTH]: {
    pattern: '[0-9]*',
    attribute_type: 'number',
    min: 0,
    parse: value => parseInt(value)
  }
})

const buildMaxCargoFieldValidation = (attributeKey) => {
  // Rule: Max Cargo fields can be toggled by vehicle type's attributes
  // So on selected vehicle type change, we need to check again to mark max cargo field
  //  - required if it's displayed
  //  - optional if it's hidden
  return Yup.number().when(['vehicle_type_id', 'vehicle_attributes'], {
    is: (vehicle_type_id, vehicle_attributes) => {
      // If type is OTHER, then all fields are displayed --> they are all required
      if (_.isUndefined(vehicle_attributes) || vehicle_type_id === OTHER_OPTION) return true

      // When no vehicle type attributes found, mark it as a required
      if (!Array.isArray(vehicle_attributes) || vehicle_attributes.length === 0) return false

      return vehicle_attributes.some(x => x.attribute_key === attributeKey)
    },
    then: Yup.number().required(i18n.t('common.required_field')),
    otherwise: Yup.number().notRequired(),
  })
}

// Use this to override validation rules of dynamic fields
export const getValidationOverride = (driverOnboarding) => {
  const REQUIRED_ERROR_MESSAGE = i18n.t('common.required_field')

  const { license_expiration_date_limit_days, normal_expiration_date_limit_days = 14 } = driverOnboarding

  // Calculate the min license expired date
  const minExpiredDate = new Date(new Date().setHours(0, 0, 0, 0))
  minExpiredDate.setDate(minExpiredDate.getDate() + (license_expiration_date_limit_days || 0) + 1)


  //  Driver License, Vehicle Registration, and Vehicle Tax expiration date
  const minNormalVehicleExpiredDate = new Date(new Date().setHours(0, 0, 0, 0))
  minNormalVehicleExpiredDate.setDate(minNormalVehicleExpiredDate.getDate() + (normal_expiration_date_limit_days || 0) + 1)
  return {
    [MAX_CARGO]: Yup.mixed().notRequired(),
    [LIFE_TIME_LICENSE]: Yup.mixed().notRequired(),
    address_label: Yup.mixed().notRequired(),
    address_component: Yup.mixed().notRequired(),

    [LOADING_CAPAICTY]: buildMaxCargoFieldValidation(LOADING_CAPAICTY),
    [VEHICLE_LENGHT]: buildMaxCargoFieldValidation(VEHICLE_LENGHT),
    [VEHICLE_WIDTH]: buildMaxCargoFieldValidation(VEHICLE_WIDTH),
    [VEHICLE_HEIGHT]: buildMaxCargoFieldValidation(VEHICLE_HEIGHT),

    [LICENSE_EXPIRATION_DATE]: Yup.mixed().when(LIFE_TIME_LICENSE, {
      is: true,
      then: Yup.mixed().notRequired(),
      otherwise: Yup.date()
        .nullable()
        .required(REQUIRED_ERROR_MESSAGE)
        .min(minExpiredDate, i18n.t('common.license_expiration_date_invalid'))
    }),
    [VEHICLE_TAX_EXPIRATION_DATE]: Yup.date()
      .nullable()
      .required(REQUIRED_ERROR_MESSAGE)
      .min(minNormalVehicleExpiredDate, i18n.t('common.vehicle_tax_expiration_date_invalid')),
    [VEHICLE_REGISTRATION_EXPIRATION_DATE]: Yup.date()
      .nullable()
      .required(REQUIRED_ERROR_MESSAGE)
      .min(minNormalVehicleExpiredDate, i18n.t('common.vehicle_registration_expiration_date_invalid')),
    [VEHICLE_KEUR_EXPIRATION_DATE]: Yup.date()
      .nullable()
      .required(REQUIRED_ERROR_MESSAGE)
      .min(minNormalVehicleExpiredDate, i18n.t('common.vehicle_keur_expiration_date_invalid')),
    [OTHER_BANK_NAME]: Yup.mixed().when(BANK_NAME, {
      is: OTHER_OPTION.toString(),
      then: Yup.string().required(REQUIRED_ERROR_MESSAGE),
      otherwise: Yup.mixed().notRequired()
    }),
    [OTHER_COOLANT_TYPE]: Yup.mixed().when(COOLANT_TYPE, {
      is: OTHER_OPTION.toString(),
      then: Yup.string().required(REQUIRED_ERROR_MESSAGE),
      otherwise: Yup.mixed().notRequired()
    }),
    [GCASH_EMAIL_ADDRESS]: Yup.string()
    .matches(EMAIL_REGEX, i18n.t('common.invalid_email')),
  }
}