import { GET_DRIVER_ONBOARDING_ATTRIBUTES, GET_DRIVER_ONBOARDING_ATTRIBUTES_FAILED, GET_DRIVER_ONBOARDING_ATTRIBUTES_SUCCESS, UPDATE_DYNAMIC_FIELD, UPDATE_SECTION_A } from 'actions/actionType'
import { CREATE_DRIVER_ONBOARDING, UPDATE_DRIVER_ONBOARDING } from 'constants/appConstants'
import { ADDRESS, SECTION_B, SECTION_C, WAITING_UPDATE_UNAPPROVED_INFO } from 'constants/dynamicFieldConstants'
import i18n from 'i18n/i18n'
import * as _ from 'lodash'
import { transformDocumentKeyToSignatureKey } from 'utils/common'
import { addMoreFieldsToSectionBIfNeeded, buildInitialErrors, buildInitialValues, buildValidationSchema, retrieveGroupName, statisticAttributeKeys, transformDynamicFieldToUiField, buildVerifyInfosWithNewField } from 'utils/driverOnboardingHelper'

const initialState = {
  // Temporary data to pass between CompleteApplication and SelectAddress page
  sectionAData: {},
  sectionAErrors: {},
  originalSectionAData: {},
  originalSectionAErrors: {},

  groupsInSectionB: [],
  groupsInSectionC: [],
  driverOnboarding: {},

  driverAttributeKeys: [],
  vehicleAttributeKeys: [],

  attachmentKeys: [],
  vehicleInfoKeys: [],
  allAttributeKeys: [],
  allDynamicFields: [],
  fieldAndSectionMap: {},

  // use area's settings for building valdiation schema
  // Assume:
  //  - All areas of a country --> use the same driver_set_components_specific_address settings
  //  - In section B, C, user only changes address inside current country (not another country)
  // --> That's why we just use settings of current area, no need to update this state when address changes.
  currentArea: {},
  initialValues: {},
  originalValues: {},
  initialErrors: {},
  originalErrors: {},
  validationSchema: {},
  error: '',
}

const createDriverOnboarding = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DRIVER_ONBOARDING:
    case UPDATE_DRIVER_ONBOARDING: {
      // When driver onboarding is UPDATED
      // Lets check if driver attributes are available or not
      //  - If yes, re-compute all the states that depend on driver onboarding
      //  - If no, just simply update driver onboarding state
      const driverOnboarding = { ...state.driverOnboarding, ...action.data }
      const newState = {
        driverOnboarding,
      }

      if (state.allAttributeKeys.length > 0) {
        const { allAttributeKeys, allDynamicFields, originalValues, currentArea } = state
        const initialErrors = buildInitialErrors(driverOnboarding)
        const initialValues = {
          ...state.initialValues,
          ...buildInitialValues({
            allAttributeKeys,
            driverOnboarding,
            allDynamicFields,
          })
        }

        if (driverOnboarding.approve_status === WAITING_UPDATE_UNAPPROVED_INFO) {
          // IMPORTANT: DO THIS BEFORE BUILDING SCHEMA
          // In case a document field is rejected, but user attempts to update
          // We'll treat it as a valid one
          allAttributeKeys
            .filter(key => key !== 'address_components') // dont delete this key no matter what
            .filter(key => {
              // For document, we should check the signature key instead of document key
              // If the key is not a document key, the original key will be returned
              const signatureKey = transformDocumentKeyToSignatureKey(key)
              const currentValue = initialValues[signatureKey]
              const rejectedValue = originalValues[signatureKey]

              return initialErrors[key] && (currentValue !== rejectedValue)
            })
            .forEach(key => {
              delete initialErrors[key]
            })
        }

        // Build initial errors for address
        if (!initialErrors[ADDRESS] && Array.isArray(driverOnboarding.address_components) && driverOnboarding.address) {
          const verifyCountry = JSON.parse(window.localStorage.getItem('dlvr_verifyCountry'))
          const currentCountryCode = verifyCountry.country_code

          const countryComponent = driverOnboarding.address_components.find(x => x.types.includes('country'))
          const addressCountry = countryComponent ? countryComponent.short_name.toLowerCase() : ''

          if (currentCountryCode !== addressCountry) {
            initialErrors.address = i18n.t('common.invalid_address')
          }
        }

        const validationSchema = buildValidationSchema({
          allDynamicFields,
          driverOnboarding,
          initialErrors,
          initialValues,
          currentArea
        })

        newState.initialValues = initialValues
        newState.initialErrors = initialErrors
        newState.validationSchema = validationSchema
      }

      return {
        ...state,
        ...newState,
      }
    }

    case GET_DRIVER_ONBOARDING_ATTRIBUTES: {
      return {
        ...state,
        error: '',
      }
    }

    case UPDATE_DYNAMIC_FIELD: {
      // TODO: to simplify this logic, we may need the help of Immer lib
      // AS Immutable rule, we need to clone to new array, object
      // so here we have 3 nested level to clone >.<
      // groups --> group --> field
      const { groupName, attribute_key, changes } = action.payload
      const newGroupsInSectionB = [...state.groupsInSectionB]
      const groupIndex = newGroupsInSectionB.findIndex(x => x.name === groupName)
      if (groupIndex < 0) return { ...state }

      const newGroup = { ...newGroupsInSectionB[groupIndex] }
      const newFieldsInGroup = [...newGroup[groupName]]
      const fieldIndex = newFieldsInGroup.findIndex(x => x.attribute_key === attribute_key)
      if (fieldIndex < 0) return { ...state }

      const newField = {
        ...newFieldsInGroup[fieldIndex],
        ...changes,
      }

      newFieldsInGroup[fieldIndex] = newField
      newGroup[groupName] = newFieldsInGroup
      newGroupsInSectionB[groupIndex] = newGroup

      return {
        ...state,
        groupsInSectionB: newGroupsInSectionB,
      }
    }

    case GET_DRIVER_ONBOARDING_ATTRIBUTES_SUCCESS: {
      // NOW ... MAGIC HAPPENS
      // We need to transform the raw data returned from API --> to the one that ready to render on UI
      // Each ready dynamic field should have
      //  - all dynamic attributes from api
      //  - plus a list of [disabled, visible, onChange, requireOtherText, optionList]
      const stateDriverOnboarding = state.driverOnboarding
      const currentArea = _.get(action, 'payload.currentArea', {})
      const sectionList = _.get(action, 'payload.sectionsByArea.vehicle_attribute_college', [])
      const onboardingSetting = _.get(action, 'payload.onboardingSetting', {})
      const sectionB = sectionList.find(x => Array.isArray(x[SECTION_B]))
      const sectionC = sectionList.find(x => Array.isArray(x[SECTION_C]))
      const originalGroupsInSectionB = sectionB ? sectionB[SECTION_B] : []
      const originalGroupsInSectionC = sectionC ? sectionC[SECTION_C] : []
      
      const driverOnboarding = {
        ...stateDriverOnboarding,
        verify_infos: buildVerifyInfosWithNewField(stateDriverOnboarding, originalGroupsInSectionB, originalGroupsInSectionC)
      }

      const optionData = _.get(action, 'payload.optionData', {})
      const rejectedFields = _.get(driverOnboarding, 'verify_infos', []).map(item => item.attribute_name)
      const groupsInSectionB = addMoreFieldsToSectionBIfNeeded(originalGroupsInSectionB, driverOnboarding)
        .map(group => {
          // Add name for fast access later
          const groupName = retrieveGroupName(group)
          group.name = groupName

          // Add more info to dynamic field to render on UI
          // Dont show optional fields
          group[groupName] = group[groupName]
            .map((dynamicField) => transformDynamicFieldToUiField({dynamicField, optionData, driverOnboarding, rejectedFields, onboardingSetting}))
            .filter(x => x.is_show)

          return group
        })

      const groupsInSectionC = originalGroupsInSectionC
        .map(group => {
          // Add name for fast access later
          const groupName = retrieveGroupName(group)
          group.name = groupName

          // dont show optional fields
          group[groupName] = group[groupName].filter(x => x.is_show)

          return group
        })

      const allGroupsInBothSections = [...groupsInSectionB, ...groupsInSectionC]
      const allDynamicFields = allGroupsInBothSections.reduce((result, group) => {
        const groupFields = group[group.name]
        return [...result, ...groupFields]
      }, [])

      const {
        driverAttributeKeys,
        vehicleAttributeKeys,
        attachmentKeys,
        vehicleInfoKeys,
        allAttributeKeys,
        fieldAndSectionMap,
      } = statisticAttributeKeys(allDynamicFields)

      // Build initialValues + validationSchema
      const initialErrors = buildInitialErrors(driverOnboarding)
      const initialValues = buildInitialValues({
        allAttributeKeys,
        driverOnboarding,
        allDynamicFields
      })
      const validationSchema = buildValidationSchema({
        allDynamicFields,
        driverOnboarding,
        initialErrors,
        initialValues,
        currentArea
      })

      const newState = {
        ...state,
        groupsInSectionB,
        groupsInSectionC,

        driverAttributeKeys,
        vehicleAttributeKeys,
        attachmentKeys,
        vehicleInfoKeys,
        allAttributeKeys,
        allDynamicFields,
        fieldAndSectionMap,

        currentArea,
        initialValues,
        originalValues: { ...initialValues },
        initialErrors,
        originalErrors: { ...initialErrors },
        validationSchema,
      }

      return newState
    }

    case GET_DRIVER_ONBOARDING_ATTRIBUTES_FAILED: {
      return {
        ...state,
        error: action.payload,
      }
    }

    case UPDATE_SECTION_A: {
      // Compute section A errors
      // Address is invalid if it's outside of current country
      // const verifyCountry = JSON.parse(window.localStorage.getItem('dlvr_verifyCountry'))
      // const currentCountryCode = verifyCountry.country_code

      // const addressComponents = action.payload.address_components || []
      // const countryComponent = addressComponents.find(x => x.types.includes('country'))
      // const addressCountry = countryComponent ? countryComponent.short_name.toLowerCase() : ''
      // const needToValRedirect = _.get(action,'payload.need_val_redirect')
      // const sectionAErrors = needToValRedirect && currentCountryCode !== addressCountry
      //   ? { address: i18n.t('common.invalid_address') }
      //   : {}

      let originalSectionAData = state.originalSectionAData || {}
      let originalSectionAErrors = state.originalSectionAErrors || {}
      if (_.isEmpty(originalSectionAData)) {
        originalSectionAData = action.payload.values
        originalSectionAErrors = action.payload.errors
      }

      const errors = { ...action.payload.errors, ...originalSectionAErrors}
      const sectionAErrors = {}
      for (var key of Object.keys(errors)) {
        const prevValue = _.get(originalSectionAData, key)
        const nextValue = _.get(action.payload, `values.${key}`) || _.get(state.sectionAData, key)
        if (prevValue && _.isEqual(prevValue, nextValue)) {
          sectionAErrors[key] = errors[key]
        }
      }

      return {
        ...state,        
        sectionAErrors: sectionAErrors,
        sectionAData: {
          ...state.sectionAData,
          ...action.payload.values
        },
        originalSectionAData,
        originalSectionAErrors
      }
    }

    default:
      return state
  }
}

export default createDriverOnboarding
