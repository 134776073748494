export const API_AREA = 'service_areas'
export const API_DRIVER_ONBOARDING = 'driver_onboardings'
export const API_DRIVER_ONBOARDING_V4 = 'v4/driver/onboarding'
export const API_DRIVER_ONBOARDING_MENUS = 'driver_onboardings/driver_onboarding_menus'
export const API_VERIFY_TRAINING_CODE = 'v4/driver/onboarding/verify_training_code'
export const API_VERIFY_ACTIVATION_CODE = 'driver_onboardings/verify_sms_token'
export const API_RESEND_ACTIVATION_CODE = 'driver_onboardings/resend_sms_token'
export const API_DRIVER_ONBOARDING_SIGNUP = 'driver_onboardings/sign_up'
export const API_VEHICLE_TYPE_LIST = 'driver_onboardings/mapping_vehicle_types'
export const API_VEHICLE_MAKE_LIST = 'driver_onboardings/mapping_vehicle_makes'
export const API_VEHICLE_MODEL_LIST = 'driver_onboardings/mapping_vehicle_models'
export const API_SELECT_AREA_LIST = 'service_areas'
export const API_AREA_DRIVER_ONBAORDING_ATTRIBUTES = 'driver_onboarding_attributes'
export const API_AREA_PROVINCES_LIST = 'provinces'
export const API_AREA_PROVINCE_DISTRICTS_LIST = 'districts'
export const API_UPDATE_DRIVER_ONBOARDING_ATTRIBUTES = 'driver_onboardings'
export const API_UPDATE_DRIVER_ONBOARDING_ATTRIBUTES_V4 = 'driver/onboarding'
export const MAX_PER_PAGE = 50
export const API_UPLOAD_TEMPORARY_FILES = 'v1/uploader/temporary'
export const API_LOAD_ATTACHMENTS_URL ='v1/Download/url'
export const API_GET_TOS_PRIVACY_LINKS = '/settings/driver_onboardings'
export const API_GET_NOTIFICATION_SETTING = '/v1/notifications/settings'
export const API_GET_REPORT_ISSUE_LINK = 'service_areas/report_issue_link'
