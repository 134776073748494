/**
 * @flow
 */
export const MAX_FILE_SIZE_UPLOAD = 3 * Math.pow(10, 6) // bytes
export const BYTES_PER_KB = 1024
export const DEFAULT_MB_SIZE_FILE = 1024
export const DEFAULT_WIDTH_DPI = 1600
export const DEFAULT_HEIGHT_DPI = 1600
export const LIMIT_YEAR_NUMBER = 5
export const SECTION_A = 'section_a'
export const SECTION_B = 'section_b'
export const SECTION_C = 'section_c'
export const DRIVER_ATTRIBUTE = 'driver_attribute'
export const VEHICLE_ATTRIBUTE = 'vehicle_attribute'
export const PDF_FILE_EXT = 'application/pdf'
export const IMAGE_FILE_EXT = 'image/'

export const LICENSE_GROUP = 'license'
export const AGREEMENTS_GROUP = 'agreements'
export const IDENTIFICATION_GROUP = 'identification'
export const OTHER_INFORMATION_GROUP = 'other_information'
export const YOUR_VEHICLE_GROUP = 'your_vehicle'
export const BANK_MOBILE_WALLET_GROUP = 'bank/mobile_wallet'
export const GCASH_GROUP = 'gcash_setup'
export const VIBER_INFORMATION_GROUP = 'viber_information'

export const ALL_GROUPS = [
  LICENSE_GROUP,
  AGREEMENTS_GROUP,
  IDENTIFICATION_GROUP,
  OTHER_INFORMATION_GROUP,
  YOUR_VEHICLE_GROUP,
  BANK_MOBILE_WALLET_GROUP
]

export const PHOTO = 'photo'
export const DOCUMENT = 'document'
export const INTEGER = 'integer'
export const NUMBER = 'number'
export const CHECKBOXES = 'checkboxes'
export const DATE = 'date'
export const MULTIPLE_CHOICE = 'multiple_choice'
export const STRING = 'string'
export const RADIO_BUTTONS = 'radio_buttons'
export const OPTION_OTHER_VALUE = ''
export const TYPE_TITLE = 'type_title'
export const TYPE_PHONE = 'type_phone'

export const PROVINCE = 'province'
export const DISTRICT = 'district'
export const LICENSE_TYPE = 'license_type'
export const DIGITAL_SIGNTURE = 'digital_signature'
export const EMERGENCY_CONTACT_NUMBER = 'emergency_contact_number'
export const LIFE_TIME_LICENSE = 'life_time_license'
export const MAX_CARGO = 'max_cargo'
export const VEHICLE_LENGHT = 'carge_length'
export const VEHICLE_HEIGHT = 'carge_height'
export const VEHICLE_WIDTH = 'carge_width'
export const VEHICLE_VOLUME = 'vehicle_volume'
export const LICENSE_EXPIRATION_DATE = 'license_expiration_date'
export const LOADING_CAPAICTY = 'loading_capacity'
export const VEHICLE_INSURANCE = 'vehicle_insurance_image'
export const PLATE_NUMBER = 'plate_number'
export const NUMBER_PASSENGER_SEAT = 'number_passenger_seat'
export const BANK_NAME = 'bank_name'
export const OTHER_BANK_NAME = 'other_bank_name'
export const VEHICLE_BRAND_NAME = 'vehicle_brand_name'
export const VEHICLE_TYPE_ID = 'vehicle_type_id'
export const AREA_ID = 'area_id'
export const OTHER_AREA_VALUE = 'other_area_value'
export const VEHICLE_MODEL_ID = 'vehicle_model_id'
export const VEHICLE_MAKE_ID = 'vehicle_make_id'
export const VEHICLE_COLOR = 'vehicle_color'
export const VEHICLE_YEAR = 'vehicle_year'
export const VEHICLE_TAX_EXPIRATION_DATE = 'vehicle_tax_expiration_date'
export const VEHICLE_REGISTRATION_EXPIRATION_DATE = 'vehicle_registration_expiration_date'
export const VEHICLE_KEUR_EXPIRATION_DATE = 'vehicle_keur_expiration_date'
export const NAME = 'name'
export const PHONE = 'phone'
export const ADDRESS = 'address'
export const EMAIL = 'email'
export const ADDRESS_LATITUDE = 'address_latitude'
export const ADDRESS_LONGITUDE = 'address_longitude'
export const DRIVER_IMAGE = 'driver_image'
export const DRIVER_IMAGE_URL = 'driver_image_url'
export const BANK_ACCOUNT_NUMBER = 'bank_account_number'
export const CONFIRM_ACCOUNT_IS_CHECKED = 'confirm_account_is_checked'
export const TAX_ID = 'tax_id'
export const LICENSE_NUMBER = 'license_number'
export const ID_NUMBER = 'citizen_id'
export const ALTERNATE_NUMBER = 'alternate_number'
export const EMERGENCY_CONTACT_NAME = 'emergency_contact_name'
export const EMERGENCY_CONTACT_RELATIONSHIP = 'emergency_contact_relationship'
export const TEMPERATURE_CAPACITY = "temperature_capacity"
export const TOTAL_HEIGHT = "total_height"
export const TOTAL_LENGTH = "total_length"
export const REGISTERED_ADDRESS = 'registered_address'
export const CURRENT_ADDRESS = 'current_address'
export const COOLANT_TYPE = "coolant_type"
export const OTHER_COOLANT_TYPE = "other_coolant_type"
export const OTHER_TEMPERATURE_CAPACITY = "other_temperature_capacity"

export const GCASH_MOBILE_NUMBER = 'gcash_mobile_number'
export const GCASH_PROFILE_SCREENSHOT = 'gcash_profile_screenshot'
export const GCASH_FIRST_NAME = 'gcash_first_name'
export const GCASH_MIDDLE_NAME = 'gcash_middle_name'
export const GCASH_LAST_NAME = 'gcash_last_name'
export const GCASH_BIRTHDATE = 'gcash_birthdate'
export const GCASH_BIRTHPLACE = 'gcash_birthplace'
export const GCASH_HOUSE_NUMBER = 'gcash_house_number_and_street_address'
export const GCASH_BARANGAY = 'gcash_barangay'
export const GCASH_CITY = 'gcash_city'
export const GCASH_ZIP_CODE = 'gcash_zip_code'
export const GCASH_GENDER = 'gcash_gender'
export const GCASH_EMAIL_ADDRESS = 'gcash_email_address'

export const VIBER_PHONE_NUMBER = 'viber_phone_number'

export const LIMIT_YEARS_BIRTHDAY = 100
export const LIMIT_YEARS_EXPIRATION_DATE = 20

export const VEHICLE_DIMENSIONS = [
  'carge_length',
  'carge_height',
  'carge_width',
  'loading_capacity'
]
export const WAITING_APPROVED = 'waiting_approved'
export const APPROVED = 'approved'
export const WAITING_UPDATE_UNAPPROVED_INFO = 'waiting_update_unapproved_info'
export const WAITING_EXAM = 'waiting_exam'
export const EXAM_PASSED = 'exam_passed'
export const SECTION_A_COMPLETE = 'section_a_complete'

// QS status:
export const PENDING_APPROVAL = 'pending_approval' //Waiting Driver QS
export const NEEDS_PC_QS = 'needs_pc_qs' // Needs pc_qs
export const NEEDS_STICKER_QS = 'needs_stickers_qs' // Needs sticker qs
export const NEEDS_SHIPPING_QS = 'needs_shipping_qs' // Needs shipping qs

export const BACKLIST = 'backlist'
export const FAIL = 'fail'
export const ON_HOLD = 'on_hold'
export const SUSPEND_COMPETITOR_APP = "suspend_competitor_app"
export const BLACKLISTED = 'blacklisted'
export const INACTIVE = 'inactive'

// update all section(A, B, C)
export const STATUS_ALLOW_UPDATE_FIELD = [
  WAITING_UPDATE_UNAPPROVED_INFO,
  SECTION_A_COMPLETE,
  EXAM_PASSED
]

/* Actions */
export const CLICK_TO_READ = 'click_to_read'
export const CLICK_TO_SIGN = 'click_to_sign'
export const SIGNED = 'signed'
export const ARCHIVE = 'archive'

/* 
  Watermark 
  
  - Currently, the original canvas of signature is 340 x 240
  - Now we draw that image to another canvas of 190 x 97
  - To keep the aspect ratio of the original signature, we do scale:
      340 x 240 ---> 87 x 62 (water mark size)
  - For 97 = 62 + 30 (date + full name height) + 5px padding to the border bottom
*/
export const WATERMARK_HEIGHT = 62
export const WATERMARK_WIDTH = 87
export const SIGNATURE_HEIGHT = 97
export const SIGNATURE_WIDTH = 190

export const DOCUMENT_SIGNATURE_PREFIX = 'document_signature_'
export const DOCUMENT_SIGNATURED_PREFIX = 'driver_onboarding_signed'


// Max Cargo field list
export const MAX_CARGO_FIELDS = [
  LOADING_CAPAICTY,
  VEHICLE_LENGHT,
  VEHICLE_WIDTH,
  VEHICLE_HEIGHT,
]

// List of fields that we should use 50% width ONLY, default to 100% width
export const HALF_WIDTH_FIELDS = [
  LOADING_CAPAICTY,
  VEHICLE_LENGHT,
  VEHICLE_WIDTH,
  VEHICLE_HEIGHT,
  VEHICLE_YEAR,
  VEHICLE_COLOR,
  VEHICLE_VOLUME,
  TEMPERATURE_CAPACITY,
  TOTAL_HEIGHT,
  TOTAL_LENGTH
]

// To support Android call camera
export const FRONT_CAMERA = "front"
export const BACK_CAMERA = "back"
export const GALLERY_BACK_CAMERA = "back_file"

export const LIFE_TIME_LICENSE_VALUE = '0'

export const COUNTRY_CODE_THAILAND = 'th'
export const COUNTRY_CODE_PHILIPPINES = 'ph'
export const COUNTRY_CODE_INDONESIA = 'id'
export const COUNTRY_CODE_VIETNAM = 'vn'
export const COUNTRIES_USE_PROVINCE_AS_TEXTBOX = [COUNTRY_CODE_INDONESIA, COUNTRY_CODE_PHILIPPINES]
