import { OTHER_OPTION, PHONE_REGEX } from 'constants/appConstants'
import {
  ADDRESS, ADDRESS_LATITUDE, ADDRESS_LONGITUDE, APPROVED, AREA_ID, CONFIRM_ACCOUNT_IS_CHECKED, BANK_ACCOUNT_NUMBER,
  BANK_MOBILE_WALLET_GROUP, BANK_NAME, CHECKBOXES, COUNTRY_CODE_INDONESIA, COUNTRY_CODE_THAILAND, COUNTRY_CODE_VIETNAM,
  DATE, DIGITAL_SIGNTURE, DISTRICT, DOCUMENT, DRIVER_ATTRIBUTE, DRIVER_IMAGE_URL, EMAIL, GCASH_GROUP, GCASH_MOBILE_NUMBER,
  LICENSE_EXPIRATION_DATE, LIFE_TIME_LICENSE, LIFE_TIME_LICENSE_VALUE, MULTIPLE_CHOICE, NAME, NUMBER, OTHER_BANK_NAME,
  PHONE, PHOTO, PROVINCE, RADIO_BUTTONS, SECTION_A, SECTION_C, STRING, TYPE_PHONE, VEHICLE_ATTRIBUTE,
  VEHICLE_BRAND_NAME, VEHICLE_COLOR, VEHICLE_HEIGHT, VEHICLE_LENGHT, VEHICLE_MAKE_ID, VEHICLE_MODEL_ID,
  VEHICLE_TYPE_ID, VEHICLE_VOLUME, VEHICLE_WIDTH, WAITING_UPDATE_UNAPPROVED_INFO, YOUR_VEHICLE_GROUP,
  ALTERNATE_NUMBER, EMERGENCY_CONTACT_NUMBER, WAITING_EXAM, EXAM_PASSED, ALL_GROUPS, VEHICLE_TAX_EXPIRATION_DATE,
  VEHICLE_REGISTRATION_EXPIRATION_DATE, VEHICLE_KEUR_EXPIRATION_DATE, COOLANT_TYPE, OTHER_COOLANT_TYPE,
  SECTION_A_COMPLETE, SECTION_B, VIBER_PHONE_NUMBER, GALLERY_BACK_CAMERA, BACK_CAMERA
} from 'constants/dynamicFieldConstants'
import i18n from 'i18n/i18n'
import titleize from 'titleize'
import * as Yup from 'yup'
import { getSizeImage64, transformDocumentKeyToSignatureKey } from './common'
import { getAddressField, getLifeTimeAccessField, getMaxCargoField, getOtherBankNameField, getOtherCoolantTypeField } from './dynamicField'
import { getDynamicFieldOverride, getValidationOverride } from './dynamicFieldOverride'
import moment from 'moment'

const _ = require('lodash')

const SECTION_A_FIELD_KEYS = [
  NAME,
  PHONE,
  EMAIL,
  ADDRESS,
  'address_components',
  ADDRESS_LATITUDE,
  ADDRESS_LONGITUDE,
  AREA_ID,
  VEHICLE_TYPE_ID,
  VEHICLE_MAKE_ID,
  VEHICLE_MODEL_ID,
  VEHICLE_BRAND_NAME,
  DRIVER_IMAGE_URL,
  BANK_ACCOUNT_NUMBER,
  CONFIRM_ACCOUNT_IS_CHECKED
]

export const retrieveGroupName = (group) => {
  // Now, each group includes 2 keys
  //  - [group name]
  //  - position
  // So the only way to get the group name is remove position key
  // And the group name key will be the rest key.
  if (!group || Object.keys(group).length !== 2) return ''

  return Object.keys(group).filter(x => x !== 'position')[0]
}

export const loopSectionGetFields = () => {

}

/**
 * Cs config has new field and reject driver, status === WAITING_UPDATE_UNAPPROVED_INFO
 *  - new field has is_require = true but don't have value
 *  - update field reject in verify_infos
 */
export const buildVerifyInfosWithNewField = (driverOnboarding, sectionB, sectionC) => {
  const approveStatus = _.get(driverOnboarding, 'approve_status', SECTION_A_COMPLETE)
  const verify_infos = _.get(driverOnboarding, 'verify_infos', [])
  if (approveStatus === WAITING_UPDATE_UNAPPROVED_INFO) {
    let fieldErrors = []
    const sectionList = [...sectionB, ...sectionC]
    ALL_GROUPS.forEach(group => {
      const groupField = sectionList.find(s => s[group]) || null
      groupField && groupField[group] && groupField[group].forEach(field => {
        const attribute_key = _.get(field, 'attribute_key', null)
        const isRequire = _.get(field, 'is_required', false) && _.get(field, 'is_show', false)

        const possibleValues = [
          driverOnboarding[attribute_key],
          _.get(driverOnboarding, `driver_attributes.${attribute_key}`, null),
          _.get(driverOnboarding, `driver_onboarding_attachments_base64.${attribute_key}`, null),
          _.get(driverOnboarding, `vehicle_info.${attribute_key}`, null),
          _.get(driverOnboarding, `vehicle_attributes.${attribute_key}`, null),
        ]
        const invalidValues = possibleValues.every(x => _.isNil(x) || x === '')

        if (invalidValues && isRequire) {
          fieldErrors.push({
            attribute_name: attribute_key,
            attribute_value_has_updated: false,
            id: -1, // FE handle
            message: i18n.t('common.required_field'),
          })
        }

      })
    })
    const DEPENDENCY_FIELD_GROUP = [
      {
        field: BANK_NAME,
        dependency: OTHER_BANK_NAME
      }, 
      {
        field: COOLANT_TYPE,
        dependency: OTHER_COOLANT_TYPE
      }
    ]
    const new_verify_infos = verify_infos || []
    verify_infos.forEach(item => {
      DEPENDENCY_FIELD_GROUP.forEach(f => {
        if (f.field === item.attribute_name) {
          new_verify_infos.push({
            attribute_name: f.dependency,
            attribute_value_has_updated: false,
            id: -1, // FE handle
            message: item.message,
          })
        }
      })
    })

    return [...fieldErrors, ...new_verify_infos ]
  }
  return verify_infos
}

/**
 * A list of fields that can be added to section B
 *  - Life time access checkbox
 *  - Max cargo text
 */
export const addMoreFieldsToSectionBIfNeeded = (originalGroupsInSectionB, driverOnboarding) => {
  const groupsInSectionB = [...originalGroupsInSectionB]

  // Add life time access checkbox if expired date is available
  for (const group of groupsInSectionB) {
    const groupName = retrieveGroupName(group)
    const fieldList = group[groupName]
    const {
      indexToAdd: lifeTimeAccessIndex,
      field: lifeTimeAccessField,
    } = getLifeTimeAccessField(fieldList)

    if (lifeTimeAccessIndex >= 0) {
      group[groupName].splice(lifeTimeAccessIndex, 0, lifeTimeAccessField)
      break
    }
  }

  // Add max cargo if needed
  const yourVehicleGroup = groupsInSectionB.find(x => Array.isArray(x[YOUR_VEHICLE_GROUP]))
  if (yourVehicleGroup) {
    const fieldsInYourVehicleGroup = yourVehicleGroup[YOUR_VEHICLE_GROUP]
    const {
      indexToAdd: maxCargoIndex,
      field: maxCargoField
    } = getMaxCargoField(fieldsInYourVehicleGroup)
    if (maxCargoIndex >= 0) {
      yourVehicleGroup[YOUR_VEHICLE_GROUP].splice(maxCargoIndex, 0, maxCargoField)
    }

    // Add other coolant type if needed
    const {
      indexToAdd: otherCoolTypeIndex,
      field: otherCoolTypeField
    } = getOtherCoolantTypeField(fieldsInYourVehicleGroup, driverOnboarding)
    if (otherCoolTypeIndex >= 0) {
      yourVehicleGroup[YOUR_VEHICLE_GROUP].splice(otherCoolTypeIndex, 0, otherCoolTypeField)
    }
  }

  // Add address label if exists at least one address field
  const gcashGroup = groupsInSectionB.find(x => Array.isArray(x[GCASH_GROUP]))
  if (gcashGroup) {
    const fieldsInGcashGroup = gcashGroup[GCASH_GROUP]
    const {
      indexToAdd: addressIndex,
      field: addressField
    } = getAddressField(fieldsInGcashGroup)
    if (addressIndex >= 0) {
      gcashGroup[GCASH_GROUP].splice(addressIndex, 0, addressField)
    }
  }

  // Add other bank name if needed
  const mobileWalletGroup = groupsInSectionB.find(x => Array.isArray(x[BANK_MOBILE_WALLET_GROUP]))
  if (mobileWalletGroup) {
    const fieldsInMobileWalletGroup = mobileWalletGroup[BANK_MOBILE_WALLET_GROUP]
    const {
      indexToAdd: otherBankNameIndex,
      field: otherBankNameField
    } = getOtherBankNameField(fieldsInMobileWalletGroup, driverOnboarding)
    if (otherBankNameIndex >= 0) {
      mobileWalletGroup[BANK_MOBILE_WALLET_GROUP].splice(otherBankNameIndex, 0, otherBankNameField)
    }
  }

  return groupsInSectionB
}

/**
 * We'll loop through all fields and classify into categories:
 *   - Driver Attributes
 *   - Vehicle Attributes
 *   - Attachments
 */
export const statisticAttributeKeys = (allDynamicFields) => {
  const driverAttributeKeys = [BANK_ACCOUNT_NUMBER, CONFIRM_ACCOUNT_IS_CHECKED]
  const vehicleAttributeKeys = []
  const attachmentKeys = []
  const vehicleInfoKeys = [VEHICLE_TYPE_ID, VEHICLE_MAKE_ID, VEHICLE_MODEL_ID, VEHICLE_BRAND_NAME]
  const allAttributeKeys = [...SECTION_A_FIELD_KEYS]

  // Create a map where each key is dynamic field key and its value is the section it belongs to.
  const fieldAndSectionMap = SECTION_A_FIELD_KEYS.reduce((map, key) => {
    map[key] = SECTION_A
    return map
  }, {})

  fieldAndSectionMap[DRIVER_IMAGE_URL] = SECTION_B
  fieldAndSectionMap[BANK_ACCOUNT_NUMBER] = SECTION_B
  fieldAndSectionMap[CONFIRM_ACCOUNT_IS_CHECKED] = SECTION_B

  // Loop through all fields of current group
  for (const field of allDynamicFields) {
    // Add to map all fields
    fieldAndSectionMap[field.attribute_key] = field.section

    // for fields in section C, accepts all documents and photos
    if (field.section === SECTION_C) {
      if (field.attribute_type === PHOTO || field.attribute_type === DOCUMENT) {
        attachmentKeys.push(field.attribute_key)
        allAttributeKeys.push(field.attribute_key)
      }

      continue
    }

    // Always add to allAttributeKeys
    allAttributeKeys.push(field.attribute_key)

    // for photo field, don't care its object_type, just add it to attachments
    if (field.attribute_type === PHOTO) {
      attachmentKeys.push(field.attribute_key)
      continue
    }

    // otherwise, just add it the corresponding object type
    switch (field.object_type) {
      case DRIVER_ATTRIBUTE: {
        driverAttributeKeys.push(field.attribute_key)
        break
      }
      case VEHICLE_ATTRIBUTE: {
        vehicleAttributeKeys.push(field.attribute_key)
        break
      }
      default:
      // do nothing hehe
    }
  }

  return {
    driverAttributeKeys,
    vehicleAttributeKeys,
    attachmentKeys,
    allAttributeKeys,
    vehicleInfoKeys,
    fieldAndSectionMap,
  }
}

const convertValuesToOptionList = (values, attribute_key) => {
  if (!values) return []

  // values can be a string or an array
  const optionList = Array.isArray(values)
    ? values
    : (_.isString(values) ? JSON.parse(values) : [])

  const options = optionList.map(option => {
    // Each option can be a string or an array of 2 items
    //  - the first item: key
    //  - the second item: value
    const isArray = Array.isArray(option) && option.length === 2
    const value = isArray ? option[0] : option

    const labelKey = isArray ? option[1] : option
    const translateKey = attribute_key === VEHICLE_COLOR ? `common.vehicle_colors.${labelKey}` : null
    const label = translateKey
      ? titleize(i18n.t(translateKey))
      : labelKey

    return { label, value }
  })

  // In case of vehicle colors, sort it by alphabet
  if (attribute_key === VEHICLE_COLOR) {
    options.sort((optionA, optionB) => {
      // Sort by value
      if (optionA.label > optionB.label) return 1
      if (optionA.label < optionB.label) return -1
      return 0
    })
  }

  // Add OTHER option for some fields
  // Later, if you want to add for another field, just add more keys to current list
  const fieldKeysWithOtherOptionSupport = [BANK_NAME, COOLANT_TYPE]
  if (fieldKeysWithOtherOptionSupport.includes(attribute_key)) {
    options.push({
      label: i18n.t('finish_application.option_other'),
      value: OTHER_OPTION,
    })
  }

  return options
}

const isDisableField = (dynamicField, driverOnboarding) => {
  // For vehicle_volume field, it's always disabled
  if (driverOnboarding.fields_updated && driverOnboarding.fields_updated.includes(dynamicField.attribute_key)) {
    return false
  }

  if (dynamicField.attribute_key === VEHICLE_VOLUME) return true

  // Rules: this is for fields in section B + C ONLY
  //  - If status is in [APPROVED, WAITING_APRROVED] --> disable all fields
  // 
  //  - If status is WAITING_UPDATE_INFO
  //      + Approved fields: disabled
  //      + Rejected fields: enabled
  // 
  //  - If status is SECTION_A_COMPLETE: Enable all

  const approveStatus = _.get(driverOnboarding, 'approve_status', SECTION_A_COMPLETE)
  switch (approveStatus) {
    case WAITING_UPDATE_UNAPPROVED_INFO: {
      // license_expiration_date disable when life_time_license is checked
      if (dynamicField.attribute_key === LICENSE_EXPIRATION_DATE && _.get(driverOnboarding, 'driver_attributes.life_time_license')) {
        return true
      }
      // if field is life_time_license will dependency license_expiration_date
      const attribute_key = dynamicField.attribute_key === LIFE_TIME_LICENSE ? LICENSE_EXPIRATION_DATE : dynamicField.attribute_key

      const verifyInfos = _.get(driverOnboarding, 'verify_infos', [])
      const fieldVerifyInfo = verifyInfos.find(x => x.attribute_name === attribute_key)
      const isRejected = !!_.get(fieldVerifyInfo, 'message', false)

      // if rejected --> enabled, disabled otherwise
      return _.get(dynamicField, 'disabled', undefined) === undefined ? !isRejected : dynamicField.disabled
    }

    case WAITING_EXAM:
    case EXAM_PASSED:
    case SECTION_A_COMPLETE: {
      // SPECIAL CASE: we have field License Expiration Date
      // This field is depend on the checked value of Lifetime License checkbox
      if (dynamicField.attribute_key === LICENSE_EXPIRATION_DATE) {
        const isLifeTimeChecked = _.get(driverOnboarding, `driver_attributes.${LICENSE_EXPIRATION_DATE}`, '') === LIFE_TIME_LICENSE_VALUE
        // If life time is checked --> disable license expired date
        // Otherwise, enable it
        return isLifeTimeChecked
      } else if (dynamicField.attribute_key === DISTRICT) {
        // Only enable district if province is selected
        const hasProvinceValue = !!_.get(driverOnboarding, `driver_attributes.${PROVINCE}`, '')
        return !hasProvinceValue
      }

      return false
    }
    default:
       // All fields  greyed out: NEEDS_APPROVAL, APPROVED, SECTION_A_COMPLETE(isRemote = true), QS_STATUES, BACKLIST, FAIL, ON_HOLD
      return true
  }
}

export const transformDynamicFieldToUiField = ({
  dynamicField, optionData, driverOnboarding, rejectedFields = [],
  onboardingSetting
}) => {
  const { attribute_key, values, attribute_type } = dynamicField
  const dynamicFieldOverride = getDynamicFieldOverride(driverOnboarding)

  let checkIsApproved
  if (driverOnboarding.fields_updated && driverOnboarding.fields_updated.includes(attribute_key)) {
    checkIsApproved = false
  } else {
    checkIsApproved = driverOnboarding.approve_status === APPROVED
    || (driverOnboarding.approve_status === WAITING_UPDATE_UNAPPROVED_INFO && !rejectedFields.includes(attribute_key))
  }

  let useCameraAndroid
  if(attribute_type === PHOTO && onboardingSetting.photo_default_method) {
    if(onboardingSetting.photo_default_method === GALLERY_BACK_CAMERA) {
      const isDisallowUploadFromGallery = onboardingSetting.photo_not_allow_gallery ? onboardingSetting.photo_not_allow_gallery.includes(attribute_key) : false
      useCameraAndroid = isDisallowUploadFromGallery ? BACK_CAMERA : GALLERY_BACK_CAMERA
    } else {
      const isAllowUploadFromGallery = onboardingSetting.photo_allow_gallery ? onboardingSetting.photo_allow_gallery.includes(attribute_key) : false
      useCameraAndroid = isAllowUploadFromGallery ? GALLERY_BACK_CAMERA : BACK_CAMERA
    }
  }

  let useWebSectionBForce
  if(attribute_type === PHOTO && onboardingSetting?.web_section_b_force) {
    useWebSectionBForce = onboardingSetting?.web_section_b_force
  }

  const uiFieldWithMoreAttributes = {
    ...dynamicField,

    disabled: isDisableField(dynamicField, driverOnboarding),
    isApproved: checkIsApproved,

    // parse option list
    optionList: optionData[attribute_key] || convertValuesToOptionList(values, attribute_key),

    // 
    ...(useCameraAndroid && { useCameraAndroid }),

    //
    ...(useWebSectionBForce && { useWebSectionBForce }),
    // add overrides
    ...dynamicFieldOverride[attribute_key]
  }

  return uiFieldWithMoreAttributes
}

const getDefaultValueBasedOnType = (attributeType, defaultData) => {
  switch (attributeType) {
    case CHECKBOXES:
      return false

    case RADIO_BUTTONS:
      return _.get(defaultData,'optionList[0].value', null)// assume admin setup list of of options with index starting from zero

    case DATE:
    case MULTIPLE_CHOICE:
      return null

    case PHOTO:
    case STRING:
    case NUMBER:
    case DOCUMENT:
    default:
      return ''
  }
}

export const buildInitialValues = ({ allAttributeKeys, driverOnboarding, allDynamicFields }) => {
  const dynamicFieldsMap = allDynamicFields.reduce((resultMap, dynamicField) => {
    resultMap[dynamicField.attribute_key] = dynamicField
    return resultMap
  }, {})

  const initialValues = {
    [LIFE_TIME_LICENSE]: false,
    notRequiredEmail: !driverOnboarding.email,
  }

  // We don't know where should we take the value for a key
  // So just try all of possible places that we can take :P
  for (const key of allAttributeKeys) {
    const attributeType = dynamicFieldsMap[key] ? dynamicFieldsMap[key].attribute_type : STRING
    const defaultValue = getDefaultValueBasedOnType(attributeType, dynamicFieldsMap[key])

    // Make sure 0 is also a valid value, instead of falsy 
    const possibleValues = [
      driverOnboarding[key],
      _.get(driverOnboarding, `driver_attributes.${key}`),
      _.get(driverOnboarding, `driver_onboarding_attachments_base64.${key}`),
      _.get(driverOnboarding, `vehicle_info.${key}`),
      _.get(driverOnboarding, `vehicle_attributes.${key}`),
    ]
    const validValues = possibleValues.filter(x => !_.isNil(x) && x !== '')

    initialValues[key] = validValues.length > 0 ? validValues[0] : defaultValue
  }

  // Init agreements in section C
  const attachments = driverOnboarding.driver_onboarding_attachments_base64 || {}
  // As we will display signature on each document 
  // so we init like this:
  //  - key: document key
  //  - value: the URL of signature of the document (instead of document url)
  Object.keys(attachments).forEach(key => {
    const signatureKey = transformDocumentKeyToSignatureKey(key)
    initialValues[key] = attachments[signatureKey] || attachments[key]
  })

  // SPECIAL CASE: License Expiration Date
  // If its value = '0'
  // --> Life time license is checked
  // --> and license expiration date is null
  if (initialValues[LICENSE_EXPIRATION_DATE] === LIFE_TIME_LICENSE_VALUE) {
    initialValues[LICENSE_EXPIRATION_DATE] = null
    initialValues[LIFE_TIME_LICENSE] = true
  }

  // SPECIAL CASE: Phone should be truncate the first 3 characters (plus sign and country code characters)
  const phoneKeys = [PHONE, GCASH_MOBILE_NUMBER, ALTERNATE_NUMBER, EMERGENCY_CONTACT_NUMBER, VIBER_PHONE_NUMBER]
  phoneKeys
    .filter(key => initialValues[key] && initialValues[key].includes('+'))
    .forEach(key => {
      initialValues[key] = initialValues[key].substring(3)
    })

  // SPECIAL CASE: Bank name + Other text
  const initialBankName = initialValues[BANK_NAME]
  if (initialBankName) {
    const bankNameField = allDynamicFields.find(x => x.attribute_key === BANK_NAME) || {}
    const bankList = bankNameField.optionList || []

    // In case selected value is available in bank list, keep it
    // Otherwise, mark it as other and fill its value to OTHER text field
    const isAvailableBank = bankList.findIndex(x => x.value === initialBankName) >= 0
    initialValues[BANK_NAME] = isAvailableBank ? initialBankName : OTHER_OPTION
    initialValues[OTHER_BANK_NAME] = isAvailableBank ? '' : initialBankName
  } else {
    initialValues[BANK_NAME] = null
    initialValues[OTHER_BANK_NAME] = ''
  }

  // SPECIAL CASE: Coolant Type + Other text
  const initialCoolantType = initialValues[COOLANT_TYPE]
  if (initialCoolantType) {
    const coolantTypeField = allDynamicFields.find(x => x.attribute_key === COOLANT_TYPE) || {}
    const coolantList = coolantTypeField.optionList || []

    // In case selected value is available in bank list, keep it
    // Otherwise, mark it as other and fill its value to OTHER text field
    const isAvailableCoolant = coolantList.findIndex(x => x.value === initialCoolantType) >= 0
    initialValues[COOLANT_TYPE] = isAvailableCoolant ? initialCoolantType : OTHER_OPTION
    initialValues[OTHER_COOLANT_TYPE] = isAvailableCoolant ? '' : initialCoolantType
  } else {
    initialValues[COOLANT_TYPE] = null
    initialValues[OTHER_COOLANT_TYPE] = ''
  }

  // SPECIAL CASE: Volume = length * width * height
  if (allAttributeKeys.includes(VEHICLE_BRAND_NAME)) {
    const length = allAttributeKeys.includes(VEHICLE_LENGHT) ? initialValues[VEHICLE_LENGHT] : 1
    const width = allAttributeKeys.includes(VEHICLE_WIDTH) ? initialValues[VEHICLE_WIDTH] : 1
    const height = allAttributeKeys.includes(VEHICLE_HEIGHT) ? initialValues[VEHICLE_HEIGHT] : 1

    // For Indonesia, they use cm for width, height and length --> volume is in cm3
    // Then we need to convert from cm3 --> m3, that's why we need to divide by 10^6 = 1e6
    const volume = driverOnboarding.country_code === COUNTRY_CODE_INDONESIA
      ? length * width * height / 1e6
      : length * width * height

    // The calculation: 1/1 helps to remove zeroes after decimal point
    // Eg: 123.00 --> 123
    initialValues[VEHICLE_VOLUME] = volume.toFixed(2) * 1 / 1
  }

  return initialValues
}

export const buildInitialErrors = (driverOnboarding) => {
  if (!driverOnboarding || !driverOnboarding.id) return {}
  // In case status is WAITING_APPROVED
  // We'll receive a list of verify info object
  // Base on this, we'll set the inital errors for Formik
  const approveStatus = driverOnboarding.approve_status
  if (approveStatus !== WAITING_UPDATE_UNAPPROVED_INFO) return {}

  const verifyInfoList = driverOnboarding.verify_infos || []
  return verifyInfoList.reduce((result, info) => {
    // Auto append Rejected to the message from API
    const errorMessage = info.message
      ? info.message
      : ''

    if (errorMessage && !info.attribute_value_has_updated) {
      const attributeKey = (info.attribute_name === 'driver_image') ? DRIVER_IMAGE_URL : info.attribute_name
      result[attributeKey] = info.message
    }

    return result
  }, {})
}

const getSchemaValueByField = ({ field }) => {
  if (!field) return null

  // For digital signature, we have a special schema for it
  if (field.attribute_key === DIGITAL_SIGNTURE) {
    return Yup.string()
      .nullable()
      .required(i18n.t('common.required_field'))
      .test(
        'shoud-have-a-clear-signature',
        i18n.t('agreement.unclear_signature_error'),
        value => {
          // In case of empty/null, mark this case as invalid
          if (!value) return false

          // If value is not a base64 string, it should be an URL returned from API
          // Treat it as a valid string 
          if (!value.includes('data:image/')) return true

          // A clear signature should have at least 4kb in size
          // 4kb come from my several tests
          return getSizeImage64(value) >= 4
        }
      )
  }

  let schemaValue = null
  switch (field.attribute_type) {
    case NUMBER:
      schemaValue = Yup.number().min(0, i18n.t('common.invalid_number'))
      break
    case CHECKBOXES:
      schemaValue = Yup.boolean()
      break
    case TYPE_PHONE:
      schemaValue = Yup.string()
        .min(7, i18n.t('common.invalid_phone_number'))
        .max(13, i18n.t('common.invalid_phone_number'))
        .matches(PHONE_REGEX, i18n.t('common.invalid_phone_number'))
      break
    case PHOTO:
    case DOCUMENT:
    case DATE:
    case MULTIPLE_CHOICE:
    case STRING:
    case RADIO_BUTTONS:
    default:
      schemaValue = Yup.string()
  }

  // Add required error message
  // For checkbox, it's required user to check it
  const errorMessage = i18n.t('common.required_field')
  if (field.is_required) {
    if (field.attribute_type === CHECKBOXES) {
      schemaValue = schemaValue.oneOf([true], errorMessage)
    } else {
      schemaValue = schemaValue.required(errorMessage)
    }
  }

  // Always allow nullable() to prevent showing weird validation error when value is null
  schemaValue = schemaValue.nullable()

  return schemaValue
}


const getSectionAValidationSchema = (currentArea) => {
  const verifyCountry = JSON.parse(window.localStorage.getItem('dlvr_verifyCountry'))
  const countryCode = verifyCountry.country_code
  const REQUIRED_ERROR = i18n.t('common.required_field')
  const requiredAddressComponents = _.get(currentArea, 'settings.driver_set_components_specific_address', '')
    .split(',')
    .filter(x => !!x)
    .map(x => x.trim())

  return {
    [NAME]: Yup.string().trim().required(REQUIRED_ERROR).nullable(),
    [PHONE]: Yup.string()
      .nullable()
      .required(REQUIRED_ERROR)
      .min(7, i18n.t('common.invalid_phone_number'))
      .max(13, i18n.t('common.invalid_phone_number'))
      .matches(PHONE_REGEX, i18n.t('common.invalid_phone_number')),

    notRequiredEmail: Yup.bool().notRequired(),
    [EMAIL]: countryCode !== COUNTRY_CODE_THAILAND
      ? Yup.string().notRequired()
      : Yup.string()
        .nullable()
        .email(i18n.t('common.invalid_email'))
        .when('notRequiredEmail', {
          is: true,
          then: Yup.string().notRequired(),
          otherwise: Yup.string().required(REQUIRED_ERROR),
        }),

    [ADDRESS]: Yup.string()
      .required(REQUIRED_ERROR)
      .nullable()
      .test('should-include-all-required-address-component', i18n.t('common.need_specific_address'), function (value) {
        // If no required address components specificed --> all address are allow
        if (!Array.isArray(requiredAddressComponents) || requiredAddressComponents.length === 0) return true

        // If address_components is null --> all address are allow (as no data to validate)
        // Why we have this case:
        //  - address_components is only available in section A
        //  - when we're at section B,C, no address_components is available until user select a new address
        // ---> skip validation until then
        const { address_components } = this.parent // current address components
        if (!Array.isArray(address_components) || address_components.length === 0) return true

        return requiredAddressComponents.every(componentType =>
          address_components.some(x => x.types.includes(componentType))
        )
      })
      .test('should-be-inside-current-country', i18n.t('common.invalid_address'), function (value) {
        // If address_components is null --> all address are allow (as no data to validate)
        // Why we have this case:
        //  - address_components is only available in section A
        //  - when we're at section B,C, no address_components is available until user select a new address
        // ---> skip validation until then
        const { address_components } = this.parent // current address components
        if (!Array.isArray(address_components) || address_components.length === 0) return true

        const countryComponent = address_components.find(x => x.types.includes('country'))
        const country = countryComponent ? countryComponent.short_name.toLowerCase() : ''
        return country === countryCode
      }),
    [ADDRESS_LATITUDE]: Yup.number().required(REQUIRED_ERROR).nullable(),
    [ADDRESS_LONGITUDE]: Yup.number().required(REQUIRED_ERROR).nullable(),

    [AREA_ID]: Yup.number()
      .required(REQUIRED_ERROR)
      .nullable(),

    [VEHICLE_TYPE_ID]: Yup.number().required(REQUIRED_ERROR).nullable(),
    [VEHICLE_MAKE_ID]: Yup.number().required(REQUIRED_ERROR).nullable(),
    [VEHICLE_MODEL_ID]: Yup.number().required(REQUIRED_ERROR).nullable(),

    [VEHICLE_BRAND_NAME]: Yup.string()
      .nullable()
      .when(['area_id', 'vehicle_type_id', 'vehicle_model_id'], {
        is: (area_id, vehicle_type_id, vehicle_model_id) => {
          // In case area_id is OTHER
          // Vehicle brand name will be required if model is OTHER
          if (area_id === OTHER_OPTION) return vehicle_model_id === OTHER_OPTION

          // In case area_id is not OTHER
          // It's required when either vehicle_type_id or vehicle_model_id is other
          // Otherwise, it's optional

          // If return true, use then clause (required field)
          // Else use otherwise clause (optional field)
          return vehicle_type_id === OTHER_OPTION
            || vehicle_model_id === OTHER_OPTION
        },
        then: Yup.string().required(REQUIRED_ERROR),
        otherwise: Yup.string(),
      }),

    [DRIVER_IMAGE_URL]: Yup.string()
      .nullable()
      .when([AREA_ID], {
        is: OTHER_OPTION,
        then: Yup.string(),
        otherwise: Yup.string().required(REQUIRED_ERROR)
      }),

    [BANK_ACCOUNT_NUMBER]: [COUNTRY_CODE_INDONESIA, COUNTRY_CODE_VIETNAM].includes(countryCode)
      ? Yup.string().required(REQUIRED_ERROR).min(7, i18n.t('common.invalid_account_number')).max(20, i18n.t('common.invalid_account_number'))
      : Yup.string().notRequired().min(7, i18n.t('common.invalid_account_number')).max(10, i18n.t('common.invalid_account_number')),

    confirm_account_is_checked: [COUNTRY_CODE_INDONESIA, COUNTRY_CODE_VIETNAM].includes(countryCode)
      ? Yup.bool().oneOf([true], REQUIRED_ERROR)
      : Yup.mixed().notRequired(),
  }
}

export const buildValidationSchema = ({ allDynamicFields, driverOnboarding, initialErrors, initialValues, currentArea }) => {
  // Start with validation rules of section A
  const validationSchema = {
    ...getSectionAValidationSchema(currentArea),
  }
  const validationOverride = getValidationOverride(driverOnboarding)

  // Dynamic bind validation schema for section B + C
  for (const field of allDynamicFields) {
    const key = field.attribute_key
    const schemaValue = validationOverride[key] || getSchemaValueByField({ field, initialValues, initialErrors })
    validationSchema[field.attribute_key] = schemaValue
  }

  const DATE_GROUP = [LICENSE_EXPIRATION_DATE, VEHICLE_TAX_EXPIRATION_DATE, VEHICLE_REGISTRATION_EXPIRATION_DATE, VEHICLE_KEUR_EXPIRATION_DATE]

  // Prevent user re-enter rejected values
  for (let key of Object.keys(initialErrors)) {
    // Init validation schema if not existed yet
    validationSchema[key] = validationSchema[key] || Yup.mixed()

    // Re-map key: driver_image --> driver_image_url
    key = (key === 'driver_image') ? DRIVER_IMAGE_URL : key

    const rejectedValue = initialValues[key]
    validationSchema[key] = validationSchema[key].test(
      'should-not-reenter-rejected-value',
      initialErrors[key],
      value => {

        // 4 cases
        // falsy vs truthy
        // truthy vs falsy
        // falsy + new !== old
        // truthy + new !== old
        // --------------------
        // REASON: 
        //  - dropdown use string but its value is number so they say 1 !== '1', but they are actually the same.
        //  - value or rejectedValue can be null/undefined.

        if (DATE_GROUP.includes(key)) {
          // rejectValue = 2022-02-21
          // value = Object Date
          const strValue = value && moment(value).format('YYYY-MM-DD')
          const strRejectValue = rejectedValue && moment(rejectedValue).format('YYYY-MM-DD')
          return (value && !strRejectValue)
            || (!value && strRejectValue)
            || (!value && !strRejectValue && strValue !== strRejectValue)
            || (value && strRejectValue && strValue.toString() !== strRejectValue.toString())
        }

        // true => dont show error
        return (value && !rejectedValue)
          || (!value && rejectedValue)
          || (!value && !rejectedValue && value !== rejectedValue)
          || (value && rejectedValue && value.toString() !== rejectedValue.toString())
      }
    )
  }

  return validationSchema
}
