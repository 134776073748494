import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { WIDTH_CAMERA } from "constants/appConstants"

class Webcam extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      disabledButton: true
    }

    this.video = null
    this.track = null
    this.height = 0
    this.streaming = false
  }

  componentDidMount() {
    this.openWebcamera()
  }
  componentWillUnmount() {
    // Stop camera
    if (this.track) {
      this.track.stop()
    }
  }

  openWebcamera = () => {
    if (!navigator.mediaDevices) return

    this.setState({
      disabledButton: true
    })
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then(async (stream) => {
        this.track = stream.getTracks()[0]
        if (this.video) {
          this.video.srcObject = stream
          this.video.play()
          this.eventCanplay()
          this.video.addEventListener('canplay', this.eventCanplay(), false)
        } else {
          // Stop camera when close popup
          if (this.track) {
            this.track.stop()
          }
        }

      })
      .catch(function (err) {
        console.log("An error occurred: " + err)
      })
  }

  eventCanplay = () => {
    if (!this.streaming) {
      this.height = this.video.videoHeight / (this.video.videoWidth / WIDTH_CAMERA)

      // Firefox currently has a bug where the height can't be read from
      // the video, so we will make assumptions if this happens.

      if (isNaN(this.height)) {
        this.height = WIDTH_CAMERA / (4 / 3)
      }

      this.video.setAttribute('width', WIDTH_CAMERA)
      this.video.setAttribute('height', this.height)
      this.streaming = true
    }
    this.setState({
      disabledButton: false
    })
  }

  onClickCapturePhoto = async (e) => {
    if (this.video && this.height) {
      if (this.height) {
        const canvas = document.createElement('canvas')
        canvas.width = WIDTH_CAMERA
        canvas.height = this.height

        const context = canvas.getContext('2d')
        context.drawImage(this.video, 0, 0, WIDTH_CAMERA, this.height)
        const imageBase64 = canvas.toDataURL('image/jpeg')
        this.props.updateCapturePhoto(imageBase64)
      }
      e.preventDefault()
    }
  }

  render() {
    const { t } = this.props
    const { disabledButton } = this.state
    return (
      <div
        className="popup-content popup-content__camera">
        <video
          id="video"
          className="Video"
          ref={(ref) => { this.video = ref }}
          width={WIDTH_CAMERA}
        />
        <div className="text-center">
          <button
            className="btn btn-success mt10"
            onClick={this.onClickCapturePhoto}
            disabled={disabledButton}
          >
            {t('common.take_photo')}
          </button>
        </div>
        <canvas ref={(ref) => { this.canvas = ref }} className="d-none" />
      </div>
    )
  }
}

Webcam.propTypes = {
  onClickCapturePhoto: PropTypes.func
}
Webcam.defaultProps = {
  onClickCapturePhoto: null,
}

export default withTranslation()(Webcam)