import Api from '../utils/ApiRestful'

// global api instance
const _apiInst = new Api()
window._apiInst = _apiInst

/**
 * @class Model
 */
export default class Model {
  /** @var {Api} */
  _api = _apiInst

  /** @var {Api} */
  static _apiStatic = _apiInst
}
