import {
  UPDATE_TOPLEFT_MENU
} from '../constants/appConstants'

export const updateTopLeftMenu = (data) => {
  return {
    type: UPDATE_TOPLEFT_MENU,
    data
  }
}
