import {
  UPDATE_TOPLEFT_MENU
} from '../constants/appConstants'

const updateTopLeftMenu = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TOPLEFT_MENU:
      return action.data
    default:
      return state
  }
}

export default updateTopLeftMenu
