import React, { PureComponent } from 'react'
import {
  Redirect,
} from 'react-router-dom'

// Containers
const DefaultLayout = React.lazy(() => import('../containers/DefaultLayout/DefaultLayout'))

/**
 * @class VerifyAccess
 */
export default class VerifyAccess extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isVerify: true
    }
  }

  render() {
    const { isVerify } = this.state

    return (isVerify === true)
      ? <DefaultLayout {...this.prop} />
      : <Redirect to="/sign_up" />
  }
}
