import { FACEBOOK_PIXEL_KEY_ID, FACEBOOK_PIXEL_KEY_PH, FACEBOOK_PIXEL_KEY_TH } from 'constants/appConstants';
import { COUNTRY_CODE_PHILIPPINES, COUNTRY_CODE_THAILAND, COUNTRY_CODE_VIETNAM } from 'constants/dynamicFieldConstants'

export const loadFacebookPixel = (countryCode) => {
    let key = FACEBOOK_PIXEL_KEY_ID
    switch (countryCode) {
      case COUNTRY_CODE_THAILAND:
        key = FACEBOOK_PIXEL_KEY_TH
        break;
      case COUNTRY_CODE_PHILIPPINES:
        key = FACEBOOK_PIXEL_KEY_PH
        break;
      default:
        break;
    }
  if (!window.isFbPixelLoaded) {
    // TODO: Should be able to config PixelID as a env var
    window.fbq('init', key);
    window.isFbPixelLoaded = true;
  }
}

export const trackEvent = (name, data = {}) => {
  window.fbq('trackCustom', name, data);
}

export const trackEventFBPixel = (eventName, data = {}) => {
  // Only trigger event complete registration if exists driverOnboarding data
  const countryCode = window._$g.country_code
  if (countryCode && countryCode !== COUNTRY_CODE_VIETNAM) {
    loadFacebookPixel(countryCode)
    trackEvent(eventName, data)
  }
}

export const EventNameConstant = {
  DRIVER_SIGN_UP_START : "DriverSignUp_Start",
  SECTION_A_START : "SectionA_Start",
  SECTION_A_COMPLETE : "SectionA_Complete",
  OTP_VERIFICATION : "OTP_Verification",
  APPROVAL_CODE_RECEIVED: "ApprovalCode_Received",
  SECTION_B_START: "SectionB_Start",
  SECTION_C_START: "SectionC_Start",
  DRIVER_SIGN_UP_COMPLETE: "DriverSignUp_Complete",
}