export const GET_DRIVER_ONBOARDING_ATTRIBUTES = 'GET_DRIVER_ONBOARDING_ATTRIBUTES'
export const GET_DRIVER_ONBOARDING_ATTRIBUTES_SUCCESS = 'GET_DRIVER_ONBOARDING_ATTRIBUTES_SUCCESS'
export const GET_DRIVER_ONBOARDING_ATTRIBUTES_FAILED = 'GET_DRIVER_ONBOARDING_ATTRIBUTES_FAILED'

export const UPDATE_DYNAMIC_FIELD = 'UPDATE_DYNAMIC_FIELD'
export const UPDATE_SECTION_A = 'UPDATE_SECTION_A'

/* E-Training */
export const GET_E_TRAINING_EXAMS_SUCCESS = 'GET_E_TRAINING_EXAMS_SUCCESS'
export const GET_E_TRAINING_EXAMS_FAILED = 'GET_E_TRAINING_EXAMS_FAILED'
export const UPDATE_E_TRAINING_EXAMS_LIST_STATUS = 'UPDATE_E_TRAINING_EXAMS_LIST_STATUS'
export const RESET_CURRENT_FAILED_EXAM = 'RESET_CURRENT_FAILED_EXAM'
