import { updateDynamicField } from "actions/driverOnboarding"
import { OTHER_OPTION } from "constants/appConstants"
import { BANK_MOBILE_WALLET_GROUP, BANK_NAME, CHECKBOXES, DRIVER_ATTRIBUTE, GCASH_BARANGAY, GCASH_CITY, GCASH_GROUP, GCASH_HOUSE_NUMBER, GCASH_ZIP_CODE, LICENSE_EXPIRATION_DATE, LIFE_TIME_LICENSE, MAX_CARGO, MAX_CARGO_FIELDS, OTHER_BANK_NAME, SECTION_B, STRING, TYPE_TITLE, YOUR_VEHICLE_GROUP, COOLANT_TYPE, OTHER_COOLANT_TYPE } from "constants/dynamicFieldConstants"
import i18n from 'i18n/i18n'
import * as _ from 'lodash'
import store from 'store'

const t = i18n.t.bind(i18n)

// List of hard-coded ID for fields that we add to form manually
const LIFE_TIME_ACCESS_FIELD_ID = 1001
const MAX_CARGO_FIELD_ID = 1002
const OTHER_BANK_NAME_ID = 1003
const ADDRESS_FIELD_ID = 1004
const OTHER_COOLANT_TYPE_ID = 1005

/**
 * Return index + field of life time access field that we should add to the list
 */
export const getLifeTimeAccessField = (fieldList) => {
  const DEFAULT_RESPONSE = {
    indexToAdd: -1,
    field: null,
  }
  if (!Array.isArray(fieldList) || fieldList.length === 0) return DEFAULT_RESPONSE

  const expiredDateFieldIndex = fieldList.findIndex(field => field.attribute_key === LICENSE_EXPIRATION_DATE)
  const verifyCountry = JSON.parse(window.localStorage.getItem('dlvr_verifyCountry'))
  const isInVietnamOrThailand = _.includes(['th', 'vn'], verifyCountry.country_code)

  // Business rule:
  // - Only show life time license if exists LICENSE_EXPIRATION_DATE attribute
  // - Only show in either VN or TH
  if (isInVietnamOrThailand && expiredDateFieldIndex >= 0) {
    const name = t(`finish_application.${LIFE_TIME_LICENSE}`)
    const lifeTimeLicenseField = {
      id: LIFE_TIME_ACCESS_FIELD_ID, // should be unique in list
      name,
      attribute_key: LIFE_TIME_LICENSE,
      attribute_type: CHECKBOXES,
      group: fieldList[expiredDateFieldIndex].group,
      object_type: DRIVER_ATTRIBUTE,
      position: 1,
      section: SECTION_B,
      is_required: true, // to make it required or not
      is_show: true, // show on UI
      values: [],
      onChange: ({ isChecked, form }) => {
        // Business rule:
        // When life time license is uncheck, clear expiration date value
        if (isChecked) {
          form.setFieldTouched(LICENSE_EXPIRATION_DATE, false)
          form.setFieldValue(LICENSE_EXPIRATION_DATE, null)
        }

        // Update disabled property of license expiration date field
        const action = updateDynamicField({
          groupName: fieldList[expiredDateFieldIndex].group,
          attribute_key: LICENSE_EXPIRATION_DATE,
          changes: { disabled: isChecked }
        })
        store.dispatch(action)
      }
    }

    return {
      indexToAdd: expiredDateFieldIndex + 1,
      field: lifeTimeLicenseField,
    }
  }

  return DEFAULT_RESPONSE
}

export const getMaxCargoUnit = (fieldList) => {
  if (!Array.isArray(fieldList) || fieldList.length === 0) return ''

  const unitList = fieldList
    .filter(x => MAX_CARGO_FIELDS.includes(x.attribute_key) && x.unit)
    .map(x => x.unit)

  const unitStatistics = unitList.reduce((result, unit) => {
    result[unit] = true
    return result
  }, {})
  const hasTheSameUnit = _.size(unitStatistics) === 1

  return hasTheSameUnit ? unitList[0] : ''
}

/**
 * Return an object with two keys:
 *  - index: -1 if not found, an index otherwise.
 *  - field: dynamic field data if found, otherwise null
 */
export const getMaxCargoField = (fieldList) => {
  const DEFAULT_RESPONSE = {
    indexToAdd: -1,
    field: null,
  }

  if (!Array.isArray(fieldList) || fieldList.length === 0) {
    return DEFAULT_RESPONSE
  }

  // As business rules:
  // There are four fields: loading_capacity, carge_length, carge_width and carge_height
  //  - If less than two of the four are available, don't show Max Cargo label.
  //  - If more than or equal to two, show Max Cargo label as the below:
  //     + Show unit next to Max Cargo if units of the four are the same.
  //     + Hide unit if exists one unit of the four is different from the others.
  // ----------------------
  // Solution: 
  // field: the first field of the four appear in fieldList
  // index: base on how many of the four are available + the first field's index


  // Find out the first field's index and how many of the four are available
  const { firstFieldIndex, cargoFieldsCount } = fieldList.reduce((result, currentField, currentIndex) => {
    const isCargoField = MAX_CARGO_FIELDS.includes(currentField.attribute_key)
    if (isCargoField) {
      // Save the first field's index
      result.firstFieldIndex = (result.firstFieldIndex < 0) ? currentIndex : result.firstFieldIndex
      result.cargoFieldsCount++
    }

    return result
  }, { firstFieldIndex: -1, cargoFieldsCount: 0 })

  const showMaxCargoField = cargoFieldsCount >= 2
  if (!showMaxCargoField) return DEFAULT_RESPONSE


  const firstCargoField = fieldList[firstFieldIndex]
  const maxCargoUnit = getMaxCargoUnit(fieldList)
  const unitString = maxCargoUnit ? `(${maxCargoUnit})` : ''
  const maxCargoFieldName = t(`finish_application.${MAX_CARGO}`)

  const maxCargoField = {
    id: MAX_CARGO_FIELD_ID,
    name: `${maxCargoFieldName} ${unitString}`,
    attribute_key: MAX_CARGO,
    attribute_type: TYPE_TITLE,
    group: YOUR_VEHICLE_GROUP,
    position: firstCargoField.position - 1,
    section: SECTION_B,
    values: [],
    is_required: true, // to make it required or not
    is_show: true, // show on UI
    unit: maxCargoUnit,
  }

  return {
    indexToAdd: firstFieldIndex,
    field: maxCargoField,
  }
}

/**
 * Return an object with two keys:
 *  - index: -1 if not found, an index otherwise.
 *  - field: dynamic field data if found, otherwise null
 */
export const getAddressField = (fieldList) => {
  const DEFAULT_RESPONSE = {
    indexToAdd: -1,
    field: null,
  }

  if (!Array.isArray(fieldList) || fieldList.length === 0) {
    return DEFAULT_RESPONSE
  }

  // Show address label if exists one address field (street address, barangay, city and zip code)
  // Find out the first field's index and how many of the four are available
  const { firstFieldIndex, addressFieldsCount } = fieldList.reduce((result, currentField, currentIndex) => {
    const isAddressField = [GCASH_HOUSE_NUMBER, GCASH_BARANGAY, GCASH_CITY, GCASH_ZIP_CODE].includes(currentField.attribute_key)
    if (isAddressField) {
      // Save the first field's index
      result.firstFieldIndex = (result.firstFieldIndex < 0) ? currentIndex : result.firstFieldIndex
      result.addressFieldsCount++
    }

    return result
  }, { firstFieldIndex: -1, addressFieldsCount: 0 })

  const showAddressField = addressFieldsCount > 0
  if (!showAddressField) return DEFAULT_RESPONSE


  const firstAddressField = fieldList[firstFieldIndex]
  const addressFieldName = t(`finish_application.address`)

  const addressField = {
    id: ADDRESS_FIELD_ID,
    name: addressFieldName,
    attribute_key: 'address_label',
    attribute_type: TYPE_TITLE,
    group: GCASH_GROUP,
    position: firstAddressField.position - 1,
    section: SECTION_B,
    values: [],
    is_required: true, // to make it required or not
    is_show: true, // show on UI
  }

  return {
    indexToAdd: firstFieldIndex,
    field: addressField,
  }
}


const convertValuesToOptionList = (values) => {
  if (!values) return []

  // values can be a string or an array
  const optionList = Array.isArray(values)
    ? values
    : (_.isString(values) ? JSON.parse(values) : [])

  const options = optionList.map(option => {
    // Each option can be a string or an array of 2 items
    //  - the first item: key
    //  - the second item: value
    const isArray = Array.isArray(option) && option.length === 2
    const value = isArray ? option[0] : option

    const labelKey = isArray ? option[1] : option

    return { label: labelKey, value }
  })

  options.push({
    label: i18n.t('finish_application.option_other'),
    value: OTHER_OPTION,
  })

  return options
}

// ---------------------------------
// OTHER BANK NAME FIELD
// ---------------------------------
const isOtherBankSelected = (bankNameField, driverOnboarding) => {
  if (!bankNameField) return false

  const selectedBankName = _.get(driverOnboarding, `driver_attributes.${BANK_NAME}`, null)
  if (!selectedBankName) return false

  const bankOptions = convertValuesToOptionList(bankNameField.values)
  return bankOptions.findIndex(option => option.value === selectedBankName) === -1
}

export const getOtherBankNameField = (fieldsInWalletGroup, driverOnboarding) => {
  const DEFAULT_RESPONSE = {
    indexToAdd: -1,
    field: null,
  }
  if (!Array.isArray(fieldsInWalletGroup) || fieldsInWalletGroup.length === 0) return DEFAULT_RESPONSE

  const bankNameFieldIndex = fieldsInWalletGroup.findIndex(field => field.attribute_key === BANK_NAME && field.is_show)
  if (bankNameFieldIndex < 0) return DEFAULT_RESPONSE

  // Business rule:
  //  - When user select OTHER option of bank name, show other_bank_name textbox
  //  - Default to hide this textbox
  const isOtherSelected = isOtherBankSelected(fieldsInWalletGroup[bankNameFieldIndex], driverOnboarding)
  const name = t(`common.${OTHER_BANK_NAME}`)

  const isDisable = !driverOnboarding.verify_infos.some((value) => value.attribute_name === BANK_NAME)

  const otherBankNameField = {
    id: OTHER_BANK_NAME_ID,
    name: '', // as we don't want to show label
    attribute_key: OTHER_BANK_NAME,
    attribute_type: STRING,
    group: BANK_MOBILE_WALLET_GROUP,
    position: 1, // it doesn't matter

    placeholder: name,
    visible: isOtherSelected,
    section: SECTION_B,
    is_required: true, // to make it required or not
    is_show: true, // show on UI
    disabled: isDisable,
  }

  return {
    indexToAdd: bankNameFieldIndex + 1,
    field: otherBankNameField,
  }
}

// ---------------------------------
// OTHER COOLANT TYPE FIELD
// ---------------------------------
const isOtherCoolantTypeSelected = (coolantTypeField, driverOnboarding) => {
  if (!coolantTypeField) return false

  const selectedCoolantType = _.get(driverOnboarding, `vehicle_attributes.${COOLANT_TYPE}`, null)
  if (!selectedCoolantType) return false

  const coolantTypeOptions = convertValuesToOptionList(coolantTypeField.values)
  return coolantTypeOptions.findIndex(option => option.value === selectedCoolantType) === -1
}

export const getOtherCoolantTypeField = (fieldsInYourVehicleGroup, driverOnboarding) => {
  const DEFAULT_RESPONSE = {
    indexToAdd: -1,
    field: null,
  }
  if (!Array.isArray(fieldsInYourVehicleGroup) || fieldsInYourVehicleGroup.length === 0) return DEFAULT_RESPONSE

  const coolantTypeFieldIndex = fieldsInYourVehicleGroup.findIndex(field => field.attribute_key === COOLANT_TYPE && field.is_show)
  if (coolantTypeFieldIndex < 0) return DEFAULT_RESPONSE

  // Business rule:
  //  - When user select OTHER option of coolant type, show other_coolant_type textbox
  //  - Default to hide this textbox
  const isOtherSelected = isOtherCoolantTypeSelected(fieldsInYourVehicleGroup[coolantTypeFieldIndex], driverOnboarding)
  const name = t(`common.${OTHER_COOLANT_TYPE}`)
  const otherField = {
    id: OTHER_COOLANT_TYPE_ID,
    name: name, // as we don't want to show label
    attribute_key: OTHER_COOLANT_TYPE,
    attribute_type: STRING,
    group: YOUR_VEHICLE_GROUP,
    position: 1, // it doesn't matter

    placeholder: name,
    visible: isOtherSelected,
    section: SECTION_B,
    is_required: true, // to make it required or not
    is_show: true, // show on UI
    disabled: false,
  }

  return {
    indexToAdd: coolantTypeFieldIndex + 1,
    field: otherField,
  }
}