import React, { PureComponent } from 'react'
import { Redirect } from 'react-router-dom'
import { addQueryParamToUrl, getParamFromURL, isWebViewAndroid } from 'utils/common'

/**
 * @class RedirectHelper
 */
class RedirectHelper extends PureComponent {
  constructor(props) {
    super(props)

    // Init state
    this.state = {
      key: null,
      props: null
    }

    // Bind method(s)
    this.go = this.go.bind(this)
  }
  

  /**
   * @public
   */
  go(href, ignoreVerifyCountry = false) {
    let newHref = href
    if (isWebViewAndroid()) {
      const isOpenNC = getParamFromURL('open_nc')
      if (isOpenNC === 'true') newHref = addQueryParamToUrl(newHref, 'open_nc', true)
    }

    if (typeof href === 'number') {
      return window.history.go(newHref)
    }
    const verifyCountry = JSON.parse(window.localStorage.getItem('dlvr_verifyCountry'))
    const country = verifyCountry.isTransportify === true || ignoreVerifyCountry ? '/' : `/${verifyCountry.country_code}/`
    const to = country + newHref
    if (newHref) {
      let props = {}
      if (typeof newHref === 'string') {
        Object.assign(props, { to, push: !('/404' === to) })
      } else {
        props = to
      }
      let key = '' + (new Date().getTime() + Math.random())
      this.setState({ key, props })
    }
  }

  render() {
    let { key, props } = this.state
    return (
      props ? <Redirect key={key} push {...props} /> : null
    )
  }
}

export default RedirectHelper
