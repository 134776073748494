import {
  UPDATE_COMPETITOR_APP
} from '../constants/appConstants'

export const updateCompetitorApp = (data) => {
  return {
    type: UPDATE_COMPETITOR_APP,
    data
  }
}
