import React, { PureComponent } from 'react'

import {
  ICON_WHITE_DELIVEREE,
  ICON_WHITE_TRANSPORTIFY,
} from '../../constants/imageConstants'

/**
 * @class Loading
 */
export default class Loading extends PureComponent {
  constructor(props) {
    super()
    this.state = {
      isModal: props.isModal
    }

    this.show = this.show.bind(this)
  }

  show(isModal) {
    this.setState({ isModal })
  }

  render() {
    const {isModal} = this.state
    return (
      <div className={`vertical-scroll Modal ${isModal ? 'visible' : ''} ${isModal}`} id="loading-modal">
        <div className="locating-drivers">
          <div className="Loader">
            <div className="Loader-Icon">
              <div className="Logo">
                <img src={window._$g.isTransportify ? ICON_WHITE_TRANSPORTIFY : ICON_WHITE_DELIVEREE} alt="Icon deliveree white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export function loading() {
  return <Loading isModal='full' />
}
