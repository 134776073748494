import ApiV4 from '../utils/ApiRestfulV4'

// global api instance
const _apiInst = new ApiV4()
window._apiInst = _apiInst

/**
 * @class ModelV4
 */
export default class ModelV4 {
  /** @var {ApiV4} */
  _api = _apiInst

  /** @var {ApiV4} */
  static _apiStatic = _apiInst
}
