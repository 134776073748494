import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
// import * as Sentry from '@sentry/browser'

// project's globals
// eslint-disable-next-line
import * as _ from './global'
import App from './navigation/App'
import * as serviceWorker from './serviceWorker'

// i18n
import './i18n/i18n'

// Config store redux
import store from './store'

// import {
//   REACT_APP_SENTRY_DSN
// } from './constants/appConstants'

// Config to debug Redux from development env
if (process.env.NODE_ENV !== 'production') {
  window.store = store
} else {
  // Sentry.init({ dsn: REACT_APP_SENTRY_DSN })
}



ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
