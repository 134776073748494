import axios from 'axios'
import { API_GATEWAY_URL } from 'constants/appConstants'


/**
 * @class ApiRestfulV4
 */
export default class ApiRestfulV4
{
  /** @var {String} */
  static API_URL_ROOT = (API_GATEWAY_URL || '/api/')
  /** @var {String} */
  static API_AUTH_REFRESH_TOKEN = 'auth/refresh-token'

  /**
   */
  constructor()
  {
    // @var {String}
    this._evtKey = '' + (new Date().getTime())
  }

  /* Events */
  /**
   * @param {*} eventName
   * @param {*} listener
   */
  addEventListener(eventName, listener) {
    if (!(eventName && listener)) {
      return this
    }
    window.addEventListener(`${this._evtKey}_${eventName}`, listener, false)
    return this
  }

  /**
   * @param {*} eventName
   * @param {*} listener
   */
  removeEventListener(eventName, listener) {
    if (!eventName) {
      return this
    }
    window.removeEventListener(`${this._evtKey}_${eventName}`, listener)
    return this
  }

  /**
   * @param {*} eventName
   */
  _dispatchEvent(eventName, args) {
    let evt = new Event(`${this._evtKey}_${eventName}`)
    Object.assign(evt, { args })
    return window.dispatchEvent(evt)
  }
  //.end

  /**
   *
   * @param {Object} _config
   * @return Promise
   */
  _request(_config)
  {
    // +++
    const getLocalStorage = window.localStorage.getItem('dlvr_verifyCountry')
    const deviceModel = window.localStorage.getItem('deviceModel')
    let config = Object.assign({
      baseURL: _static.API_URL_ROOT,
      headers: {
        'Accept-Language': getLocalStorage ? JSON.parse(getLocalStorage).language_code : 'en',
        'x-device-agent': deviceModel || ''
      },
    }, _config = _config || {})

    // Send request?!
    this._dispatchEvent('beforeRequest', { config })
    let incomming = new Promise((resolve, reject) => {
      return axios(config)
        .then(response => {
          return response.data
        })
        .then(resolve)
        .catch(err => {
          // handle err
          let { response } = err
          if (!response) {
            throw err
          }
          let { data: apiData } = response
          apiData = Object.assign(apiData || {}, { _: () => response })
          return reject(apiData)
        })
    })
    this._dispatchEvent('request', { config, incomming })
    return incomming
  }

  /**
   * GET calls
   * @param {String} url
   * @param {Object} params
   * @param {Object} _config
   * @return Promise
   */
  get(url, params, _config) {
    let config = Object.assign({
      url,
      method: 'get',
      params
    }, _config || {})
    return this._request(config)
  }

  /**
   * POST calls
   * @param {String} url
   * @param {Object} data
   * @param {Object} _config
   * @return Promise
   */
  post(url, data, _config) {
    let config = Object.assign({
      url,
      method: 'post',
      data
    }, _config || {})
    return this._request(config)
  }

  /**
   * PUT calls
   * @param {String} url
   * @param {Object} data
   * @param {Object} _config
   * @return Promise
   */
  put(url, data, _config) {
    let config = Object.assign({
      url,
      method: 'put',
      data
    }, _config || {})
    return this._request(config)
  }

  /**
   * PATCH calls
   * @param {String} url
   * @param {Object} data
   * @param {Object} _config
   * @return Promise
   */
  patch(url, data, _config) {
    let config = Object.assign({
      url,
      method: 'patch',
      data
    }, _config || {})
    return this._request(config)
  }

  /**
   * DELETE calls
   * @param {String} url
   * @param {Object} params
   * @param {Object} _config
   * @return Promise
   */
  delete(url, params, _config) {
    let config = Object.assign({
      url,
      method: 'delete',
      params
    }, _config || {})
    return this._request(config)
  }
}
// Make alias
const _static = ApiRestfulV4
