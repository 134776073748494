import { API_DRIVER_ONBOARDING } from 'constants/apiConstants'
import { API_DRIVER_ONBOARDING_MENUS, API_DRIVER_ONBOARDING_SIGNUP, API_RESEND_ACTIVATION_CODE, API_UPDATE_DRIVER_ONBOARDING_ATTRIBUTES, API_VERIFY_ACTIVATION_CODE, API_VERIFY_TRAINING_CODE } from '../../constants/apiConstants'
import Model from '../Model'

/**
 * @class DriverOnboarding
 */
export default class DriverOnboarding extends Model {
  getMenus(_data = {}) {
    let data = Object.assign({}, _data)
    return this._api.get(API_DRIVER_ONBOARDING_MENUS, data)
  }

  verifyTrainingCode(_data = {}) {
    let data = Object.assign({}, _data)
    return this._api.post(API_VERIFY_TRAINING_CODE, data)
  }

  verifyActivationCode(data = {}) {
    return this._api.post(API_VERIFY_ACTIVATION_CODE, data)
  }

  resendActivationCode(data) {
    return this._api.post(API_RESEND_ACTIVATION_CODE, data)
  }

  signUp(_data = {}) {
    const approvalCode = sessionStorage.getItem('retraining_approval_code')
    const data = Object.assign({}, _data, {approval_code: approvalCode})
    return this._api.post(API_DRIVER_ONBOARDING_SIGNUP, data)
  }

  update(id, _data = {}) {
    let data = Object.assign({}, _data)
    const apiURL = `${API_UPDATE_DRIVER_ONBOARDING_ATTRIBUTES}/${id}`
    return this._api.put(apiURL, data)
  }

  sendTrainingLink(id) {
    const apiURL = `${API_DRIVER_ONBOARDING}/${id}/send_training_link`
    return this._api.get(apiURL)
  }

  sendTrainingSignInCode(id) {
    const apiURL = `${API_DRIVER_ONBOARDING}/${id}/send_etraining_signin_code`
    return this._api.post(apiURL)
  }

  resendTrainingSignInCode(id) {
    const apiURL = `${API_DRIVER_ONBOARDING}/${id}/resend_etraining_signin_code`
    return this._api.post(apiURL)
  }

  verifyTrainingSignInCode(payload) {
    const apiURL = `${API_DRIVER_ONBOARDING}/${payload.id}/etraining_signin`
    return this._api.post(apiURL, payload).then(response => response.object)
  }

  suspendCompetitorApp({id, payload}) {
    const apiURL = `${API_DRIVER_ONBOARDING}/competitors/${id}`
    return this._api.put(apiURL, payload).then(response => response.object)
  }
}
