/**
 * @flow
 */

// Logo Deliveree
import whiteLogo from '../assets/images/logo-white.png'
import greenLogo from '../assets/images/logo-green.png'
import whiteTransportifyLogo from '../assets/images/logo-transportify-white.png'
import icon404Deliveree from '../assets/images/404/deliveree-page-not-found.png'
import icon404Transportify from '../assets/images/404/transportify-page-not-found.png'
import iconWhiteDeliveree from '../assets/images/icon-logos/Icon_Deliveree_White.svg'
import iconWhiteTransportify from '../assets/images/icon-logos/Icon_Transportify_White.svg'
import appleStore from '../assets/images/app-store.svg'
import googleStore from '../assets/images/ch-play.svg'
import information from '../assets/images/i.png'
import informationHover from '../assets/images/i-hover.png'
import backButton from '../assets/images/back-button.svg'
import homeButton from '../assets/images/home.svg'
import IDFlagIcon from '../assets/images/flags/id.png'
import PHFlagIcon from '../assets/images/flags/ph.png'
import THFlagIcon from '../assets/images/flags/th.png'
import VNFlagIcon from '../assets/images/flags/vn.png'
import bgLogin from '../assets/images/bgLogin.png'
import checkCircle from '../assets/images/check-circle.png'
import photoUser from '../assets/images/photo-user.png'
import checked from '../assets/images/checked.png'
import arrowBottomGreen from '../assets/images/arrow-bottom-green.png'
import arrowTopGreen from '../assets/images/arrow-top-green.png'
import arrowBottomBlack from '../assets/images/arrow-bottom-black.png'
import checkedError from '../assets/images/checked-error.png'
import photoMountain from '../assets/images/photo-mountain.svg'
import downGreen from '../assets/images/down-green.svg'
import upRed from '../assets/images/up-red.svg'
import checkSigned from '../assets/images/check-signed.svg'
import archive from '../assets/images/archive.svg'
import arrowBottomBlackThin from '../assets/images/arrow-bottom-thin.png'
import clearIcon from '../assets/images/clear.png'
import signatureHand from '../assets/images/signature-hand.png'
import pdfIcon from '../assets/images/pdf-icon.png'
import iconInfo from '../assets/images/icon-i-green.png'
import iconConfirm from '../assets/images/icon-confirm.svg'
import approved from '../assets/images/approved.svg'
import thanksFor from '../assets/images/thanks-for.svg'
import complete from '../assets/images/complete.svg'
import checkWhite from '../assets/images/check-white.svg'
import iconError from '../assets/images/error.svg'
import currenlyLocation from '../assets/images/currenly-location.svg'
import checkedExam from '../assets/images/checked-exam.svg'
import lockedExam from '../assets/images/locked-exam.svg'
import notTakeExam from '../assets/images/not-take-exam.svg'
import iconTakePhoto from '../assets/images/icon-take-photo.svg'
import markerHome from '../assets/images/marker-home.svg'
import iconSelectFromMap from '../assets/images/select_from_map.svg'
import arrowDownGray from '../assets/images/arrow-down-gray.svg'
import iconPlus from '../assets/images/icon-plus.svg'
import iconCalendar from '../assets/images/icon-calendar.svg'
import handClick from '../assets/images/hand_click.svg'
import driverPhotoPlacehoder from '../assets/images/driver_photo_placeholder.svg'
import iconExitApp from '../assets/images/icon_exit_app.svg'
import showMoreLocation from '../assets/images/location/show-more-location.svg'
import notificationsDropDown from '../assets/images/noti-dropdown.svg'
import redOval from '../assets/images/red-oval.svg'
import notificationsIcon from '../assets/images/notification-icon.svg'
import addCircle from '../assets/images/add-circle.svg'

/* Import */

// Logo Deliveree
export const LOGO_DELIVEREE = whiteLogo
export const LOGO_DELIVEREE_GREEN = greenLogo
export const LOGO_TRANSPORTIFY = whiteTransportifyLogo
export const ICON_404_DELIVEREE = icon404Deliveree
export const ICON_404_TRANSPORTIFY = icon404Transportify
export const ICON_WHITE_DELIVEREE = iconWhiteDeliveree
export const ICON_WHITE_TRANSPORTIFY = iconWhiteTransportify
export const APPLE_STORE = appleStore
export const GOOGLE_STORE = googleStore
export const ICON_INFORMATION = information
export const ICON_INFORMATION_HOVER = informationHover
export const ICON_BACK_BUTTON = backButton
export const ICON_HOME_BUTTON = homeButton
export const ICON_FLAG_ID = IDFlagIcon
export const ICON_FLAG_PH = PHFlagIcon
export const ICON_FLAG_TH = THFlagIcon
export const ICON_FLAG_VN = VNFlagIcon
export const BG_LOGIN = bgLogin
export const CHECK_CIRCLE = checkCircle
export const PHOTO_USER = photoUser
export const ICON_CHECKED = checked
export const ARROW_BOTTOM_GREEN = arrowBottomGreen
export const ARROW_TOP_GREEN = arrowTopGreen
export const ARROW_BOTTOM_BLACK = arrowBottomBlack
export const ICON_CHECKED_ERROR = checkedError
export const PHOTO_MOUNTAIN = photoMountain
export const DOWN_GREEN = downGreen
export const UP_RED = upRed
export const CHECK_SIGNED = checkSigned
export const ARCHIVE_IMAGE = archive
export const ICON_FLAGS = {
  'PH': ICON_FLAG_PH,
  'TH': ICON_FLAG_TH,
  'ID': ICON_FLAG_ID,
  'VN': ICON_FLAG_VN
}
export const ICON_ARROW_BOTTOM_THIN = arrowBottomBlackThin
export const CLEAR_ICON = clearIcon
export const SIGNATURE_HAND = signatureHand
export const PDF_ICON = pdfIcon
export const INCON_INFO = iconInfo
export const ICON_CONFIRM = iconConfirm
export const ICON_APPROVED = approved
export const ICON_THANKS_FOR = thanksFor
export const ICON_COMPLETE = complete
export const CHECK_WHITE = checkWhite
export const ICON_ERROR = iconError
export const CURRENLY_LOCATION = currenlyLocation
export const ICON_CHECKED_EXAM = checkedExam
export const ICON_LOCKED_EXAM = lockedExam
export const ICON_NOT_TAKE_EXAM = notTakeExam
export const ICON_TAKE_PHOTO = iconTakePhoto
export const MARKER_HOME = markerHome
export const ICON_SELECT_FROM_MAP = iconSelectFromMap
export const ARROW_DOWN_GRAY = arrowDownGray
export const ICON_PLUS = iconPlus
export const ICON_CALENDAR = iconCalendar
export const HAND_CLICK = handClick
export const DRIVER_PHOTO_PLACEHOLDER = driverPhotoPlacehoder
export const ICON_EXIT_APP = iconExitApp

export const SHOW_MORE_LOCATION = showMoreLocation
export const NOTI_DROPDOWN = notificationsDropDown
export const RED_OVAL = redOval
export const NOTIFICATION_ICON = notificationsIcon
export const ADD_CIRCLE = addCircle
