/**
 * Define project's global
 */
import _ from 'lodash'

Object.assign(window, {
  // create ref shortcut
  _$g: {
    // @var {String} Theme
    theme: 'bootstrap',
    // @var {Object}
    userAuth: null,
    // utils
    _,
    country_code: '',
    language_code: '',
    isTransportify: '',
    // @var {Object} common dialogs
    dialogs: function() {
      alert('[dialogs] Not yet ready!')
    },
    // @var {Function} Redirect helper
    rdr: function() {
      alert('[rdr] Not yet ready!')
    }
  },
})

// export
export default global
