import Area from 'api/Area'
import VehicleType from 'api/VehicleType'
import DriverOnboarding from 'api/DriverOnboarding'
import DriverOnboardingV4 from 'api/DriverOnboardingV4'
import { OTHER_OPTION } from 'constants/appConstants'
import { COUNTRIES_USE_PROVINCE_AS_TEXTBOX, DISTRICT, LOADING_CAPAICTY, MAX_CARGO, PROVINCE, VEHICLE_HEIGHT, VEHICLE_LENGHT, VEHICLE_VOLUME, VEHICLE_WIDTH, YOUR_VEHICLE_GROUP, SUSPEND_COMPETITOR_APP } from 'constants/dynamicFieldConstants'
import { CREATE_DRIVER_ONBOARDING, UPDATE_DRIVER_ONBOARDING } from '../constants/appConstants'
import { GET_DRIVER_ONBOARDING_ATTRIBUTES, GET_DRIVER_ONBOARDING_ATTRIBUTES_FAILED, GET_DRIVER_ONBOARDING_ATTRIBUTES_SUCCESS, UPDATE_DYNAMIC_FIELD, UPDATE_SECTION_A } from './actionType'
import get from 'lodash/get'
import { fnSuspendCompetitorApp } from 'utils/common'

const areaApi = new Area()
const vehicleTypeApi = new VehicleType()
const driverOnboardingAPI = new DriverOnboarding()
const driverOnboardingAPIV4 = new DriverOnboardingV4()

const checkSuspendApp = async (data, state) => {
  const competitorApp = get(state, 'common.competitorApp', [])
  const id = get(data, 'id')
  const approve_status = get(data, 'approve_status', '').toLowerCase()
  const payloadSuspendApp = {
    id: id,
    payload: {
      has_competitor_app: competitorApp.length > 0,
      competitor_app_list: competitorApp.length > 0 ? competitorApp : [],
      area_id: data.area_id
    }
  }
  if (approve_status === SUSPEND_COMPETITOR_APP) {
    // get previous approve_status

    if (competitorApp.length === 0) {
      const resCompetitor = await driverOnboardingAPIV4.suspendCompetitorApp(payloadSuspendApp)
      const new_approve_status = get(resCompetitor, 'approve_status', '').toLowerCase()
      if (new_approve_status === SUSPEND_COMPETITOR_APP) {
        fnSuspendCompetitorApp(approve_status)
        return false
      }
      return resCompetitor
    } else {
      fnSuspendCompetitorApp(approve_status)
      return false
    }
  } else {
    // prevent sign in with competitor apps
    if (competitorApp.length > 0) {
      const resCompetitor = await driverOnboardingAPIV4.suspendCompetitorApp(payloadSuspendApp)
      const areaHasSuspendCompetitorAppFlow = resCompetitor?.area_has_suspend_competitor_app_flow
      if (resCompetitor && areaHasSuspendCompetitorAppFlow && resCompetitor.status === SUSPEND_COMPETITOR_APP) {
        fnSuspendCompetitorApp(approve_status)
        return false
      }
      return data
    }
    return data
  }
}

export const create = (data) => async (dispatch, getState) => {
  const state = getState()
  const driverOnboarding = await checkSuspendApp(data, state)
  if (driverOnboarding) {
    dispatch({
      type: CREATE_DRIVER_ONBOARDING,
      data: driverOnboarding
    })
    return driverOnboarding
  } else {
    return false
  }
}

export const update = (data) => async (dispatch, getState) => {
  const state = getState()
  const driverOnboarding = await checkSuspendApp(data, state)
  if (driverOnboarding) {
    dispatch({
      type: UPDATE_DRIVER_ONBOARDING,
      data: driverOnboarding
    })
    return driverOnboarding
  } else {
    return false
  }
}

export const updateDynamicField = (data) => {
  return {
    type: UPDATE_DYNAMIC_FIELD,
    payload: data
  }
}

export const toggleCargoFieldsByVehicleTypeId = (vehicleTypeId, form) => {
  return async dispatch => {
    try {
      // Base on vehicle_attributes settings, we'll decide to show/hide the following fields
      // Default to show all of them
      const affectedFieldKeys = [LOADING_CAPAICTY, VEHICLE_LENGHT, VEHICLE_WIDTH, VEHICLE_HEIGHT]
      let dynamicFields = []

      if (!vehicleTypeId || vehicleTypeId === OTHER_OPTION) {
        // no vehicle type detected, default to show all 
        dynamicFields = affectedFieldKeys.map(key => ({
          attribute_key: key,
          groupName: YOUR_VEHICLE_GROUP,
          changes: {
            visible: true,
          }
        }))
      } else {
        const params = {
          'include[]': 'vehicle_attributes'
        }
        const vehicleType = await vehicleTypeApi.getById(vehicleTypeId, params)
        const vehicleTypeAttributes = vehicleType.vehicle_attributes || []

        // We set this field to trigger validations for cargo fields
        // in case they are not in this list, make them optional
        form.setFieldValue('vehicle_attributes', vehicleTypeAttributes)

        dynamicFields = affectedFieldKeys.map(key => ({
          attribute_key: key,
          groupName: YOUR_VEHICLE_GROUP,
          changes: {
            visible: vehicleTypeAttributes.some(x => x.attribute_key === key),
          }
        }))
      }

      // Only show vehicle volume if exist one of the following field is visible: length, width or height
      dynamicFields.push({
        attribute_key: VEHICLE_VOLUME,
        groupName: YOUR_VEHICLE_GROUP,
        changes: {
          visible: dynamicFields.some(field => {
            return [VEHICLE_WIDTH, VEHICLE_LENGHT, VEHICLE_HEIGHT].includes(field.attribute_key) && field.changes.visible
          })
        }
      })

      // Only show max cargo title if exist one cargo field is visible
      dynamicFields.push({
        attribute_key: MAX_CARGO,
        groupName: YOUR_VEHICLE_GROUP,
        changes: {
          visible: dynamicFields.some(field => {
            return [LOADING_CAPAICTY, VEHICLE_WIDTH, VEHICLE_LENGHT, VEHICLE_HEIGHT].includes(field.attribute_key) && field.changes.visible
          })
        }
      })

      dynamicFields.forEach(field => {
        dispatch(updateDynamicField(field))
      })
    } catch (error) {
      console.log('Failed to toggle cargo field based on vehicle type id: ', error)
    }
  }
}

export const getDriverOnboardingAttributes = ({ areaId, vehicleTypeId, provinceId, countryCode }) => {
  return async dispatch => {
    try {
      // Show page loading
      window._$g.dialogs('page')
      dispatch({ type: GET_DRIVER_ONBOARDING_ATTRIBUTES })

      const formatCountryCode = (countryCode || '').toLowerCase()
      // Fetch attributes data
      const result = await Promise.all([
        areaApi.getDriverOnboardingAttributes(areaId, { vehicle_type_id: vehicleTypeId }),
        driverOnboardingAPIV4.getOnboardingSetting(formatCountryCode)
      ])
      const sectionsByArea = result[0].object
      const onboardingSetting = result[1]

      // Fetch area detail as we need to use some settings of the area
      const currentArea = await areaApi.getAreaById(areaId)

      // RULES for Province field
      //  - For ID + PH, it's a textbox --> no data to fetch 
      //  - For TH and others, it's a drop down --> we need to fetch data 
      if (!COUNTRIES_USE_PROVINCE_AS_TEXTBOX.includes(formatCountryCode)) {
        // Fetch province list if that field is available in dynamic field list
        const provinceList = await areaApi.getAllProvinces(areaId)

        // Fetch district list of selected province (if any)
        const districtList = provinceId
          ? await areaApi.getAllDistricts(provinceId)
          : []

        dispatch({
          type: GET_DRIVER_ONBOARDING_ATTRIBUTES_SUCCESS,
          payload: {
            sectionsByArea,
            currentArea,
            onboardingSetting,
            optionData: {
              [PROVINCE]: provinceList.map(x => ({
                value: x.id,
                label: x.name,
              })),

              [DISTRICT]: districtList.map(x => ({
                value: x.id,
                label: x.name,
              }))
            }
          }
        })

        // Hide page loading
        window._$g.dialogs('')
        return true
      }


      dispatch({
        type: GET_DRIVER_ONBOARDING_ATTRIBUTES_SUCCESS,
        payload: {
          sectionsByArea,
          onboardingSetting,
        }
      })

      // Hide page loading
      window._$g.dialogs('')

      return true
    } catch (error) {
      // Hide page loading
      window._$g.dialogs('')
      console.log('Failed oops: ', error)

      dispatch({
        type: GET_DRIVER_ONBOARDING_ATTRIBUTES_FAILED,
        payload: error.error || error.message,
      })
    }
  }
}

export const updateSectionA = (data) => {
  return {
    type: UPDATE_SECTION_A,
    payload: data,
  }
}