import {
  UPDATE_SHOW_GOOGLE_MAP
} from '../constants/appConstants'

const updateShowGoogleMap = (state = false, action) => {
  switch (action.type) {
    case UPDATE_SHOW_GOOGLE_MAP:
      return action.data
    default:
      return state
  }
}

export default updateShowGoogleMap
