import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from './resources'
import {
  TRANSPORTIFY_URL,
  ACCEPT_LANGUAGE
} from '../constants/appConstants'
import _ from 'lodash'

const isTransportify = _.includes(TRANSPORTIFY_URL, window.location.hostname)
const languageCode = isTransportify ? 'tl' : ACCEPT_LANGUAGE[`${(window.location.pathname.split('/')[1] || 'id')}`]

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: languageCode,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
