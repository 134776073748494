import {
  UPDATE_AREA
} from '../constants/appConstants'

const updateArea = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AREA:
      return action.data
    default:
      return state
  }
}

export default updateArea
