import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

const _ = window._$g._

class DynamicPopup extends PureComponent {
  constructor(props) {
    super(props)

    this.popupContentRef = null
    this.setPopupContentRef = node => this.popupContentRef = node
  }

  handleOnClose() {
    const { handleClickOutside, renderNode } = this.props
    if (!_.isUndefined(handleClickOutside)) {
      handleClickOutside()
    }
    ReactDOM.unmountComponentAtNode(renderNode)
  }

  handlePageClick = (e) => {
    const { closeOnOutsideClick } = this.props
    if (closeOnOutsideClick && this.popupContentRef && !this.popupContentRef.contains(e.target)) {
      this.handleOnClose()
    }
  }

  renderButtons() {
    const {
      renderDynamicButton,
    } = this.props

    return !_.isUndefined(renderDynamicButton) && _.size(renderDynamicButton().props.children)
      ? renderDynamicButton()
      : null
  }

  render() {
    const {
      isDefaultPopup,
      title,
      specialClass,
      specialClassContent,
      renderDynamicContent,
    } = this.props

    if (isDefaultPopup) {
      return (
        <div className={`modal-popup visible ${specialClass}`} onClick={this.handlePageClick}>
          <div className="modal-popup-content larger" ref={this.setPopupContentRef}>
            <span className="close" onClick={() => this.handleOnClose()}>×</span>
            <div className="modal-popup-header">
              <div>
                <h1>
                  {title}
                </h1>
              </div>
            </div>
            <div className="modal-popup-body">
              {renderDynamicContent()}
            </div>
            <div className="modal-popup-button">
              {this.renderButtons()}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={`Modal Common visible ${specialClass}`}>
        <div className="Popup normal-driver z-index-2">
          <div className="Box">
            <div className="Box-Content">
              <div className="Normal">
                {title.length > 0
                  && (
                    <div className="Popup-Booking-Normal">
                      <h5 dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                  )
                }
                <div className={`Popup-Booking-Custom ${specialClassContent}`}>
                  {renderDynamicContent()}
                </div>
                {this.renderButtons()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DynamicPopup.propTypes = {
  isDefaultPopup: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  title: PropTypes.string,
  specialClass: PropTypes.string,
  specialClassContent: PropTypes.string,
  renderNode: PropTypes.shape({}),
  renderDynamicContent: PropTypes.func.isRequired,
  renderDynamicButton: PropTypes.func,
  handleClickOutside: PropTypes.func
}

DynamicPopup.defaultProps = {
  isDefaultPopup: true,
  closeOnOutsideClick: false,
  title: '',
  specialClass: '',
  specialClassContent: '',
  renderNode: undefined,
  renderDynamicButton: undefined,
  handleClickOutside: undefined
}

export default DynamicPopup
